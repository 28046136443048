import React, { Component } from "react";
import moment from "moment";
import { getRequest, patchRequest } from "../../utils/httpRequest";
import Filters from "../AccountActivity/Filters";
import ActivityPagination from "../AccountActivity/ActivityPagination";
import Table from "./Table";
import { initialFiltersState } from "./helpers";

class UserActivities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transfers: [],
      filters: initialFiltersState,
      loading: true,
      currentPage: 1,
    };
  }

  componentDidMount() {
    getRequest("/crypto_transfers.json").then((response) => {
      const sortedTransfers = response.transfers.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      this.setState({
        transfers: sortedTransfers,
        totalPages: response.total_pages,
        loading: false,
      });
    });
  }

  onSelectFilter = (key, value) => {
    const { filters } = this.state;

    const updatedFilters = { ...filters, [key]: value };

    this.setState({ filters: updatedFilters });
  };

  handlePageChange = (e, currentPage) => {
    e.preventDefault();
    this.setState({ currentPage }, () => this.getTransfers());
  };

  clearFilters = (e) => {
    e.preventDefault();

    this.setState({ filters: initialFiltersState }, () =>
      this.filterTransfers(e)
    );
  };

  filterTransfers = (e) => {
    e.preventDefault();

    this.setState({ loading: true, currentPage: 1 }, () => this.getTransfers());
  }

  getTransfers() {
    const {
      filters: { startDate, endDate, status, currency, transferType, search },
      currentPage,
    } = this.state;

    const url =
      `/crypto_transfers.json?q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}&q[external_status_cont]=${status}&` +
      `q[transfer_type]=${transferType}&q[search_term]=${search}&` +
      `q[source_currency_or_destination_currency_cont]=${currency}&page=${currentPage}`;

    getRequest(url).then((response) => {
      const sortedTransfers = response.transfers.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      this.setState({
        transfers: sortedTransfers,
        totalPages: response.total_pages,
        loading: false,
      });
    });
  };

  removeTransfer = (e, transferId) => {
    e.preventDefault();

    if (
      confirm(
        "Are you sure you want to remove this transaction from your history?"
      ) == true
    ) {
      const crsfToken = $('meta[name="csrf-token"]').attr("content");

      patchRequest(
        `/crypto_transfers/${transferId}/remove.json`,
        {},
        {
          "X-CSRF-Token": crsfToken,
        }
      ).then((response) => {
        const newTransfers = this.state.transfers.filter(
          (transfer) => transfer.id !== transferId
        );

        this.setState({
          transfers: newTransfers,
        });
      });
    }
  };

  render() {
    const { currencies, hideFilters } = this.props;
    const { transfers, currentPage, totalPages, filters, loading } = this.state;

    return (
      <div className="account-activity pb-5">
        {!hideFilters && (
          <Filters
            currencies={currencies}
            filters={filters}
            filterTransfers={this.filterTransfers}
            onSelectFilter={this.onSelectFilter}
            clearFilters={this.clearFilters}
          />
        )}
        <Table
          transfers={transfers}
          removeTransfer={this.removeTransfer}
          loading={loading}
        />
        {totalPages > 1 && (
          <ActivityPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={this.handlePageChange}
          />
        )}
      </div>
    );
  }
}

export default UserActivities;
