import React from "react";
import { ReactSVG } from "react-svg";
import SelectCurrency from "./SelectCurrency";
import WalletTransfer from "./WalletTransfer";
import Preview from "./Preview";
import Actions from "./Actions";

const CryptoForm = ({
  stateParams,
  propsParams,
  handleBlockchainAddressChange,
  handleAmountChange,
  showModal,
  handleChange,
  cancelTransfer,
  refreshPage,
  submitTransferPreview,
  submitTransfer,
  handleRecipientChange,
  handleSelectedCurrencyChange
}) => (
  <form
    className="form-horizontal transfer-form px-md-3 store-ovalpixel-form"
    id="new_crypto_transfer"
  >
    <div className="amount-wrapper">
      <SelectCurrency
        type={propsParams.type}
        selectedCurrency={stateParams.selectedCurrency}
        currencies={propsParams.currencies}
        handleChange={handleChange}
        handleSelectedCurrencyChange={handleSelectedCurrencyChange}
      />
      <div className="form-group row required mb-5">
        <label className="form-label">Amount</label>
        <div className="input-group mb-2 mr-sm-2">
          <div className="input-group-prepend">
            <div
              className="input-group-text selected-currency font-2 pl-1 pr-1"
              style={{ color: "#e3a76f", focus: { color: "#414da7" } }}
            >
              {stateParams.selectedCurrency}
            </div>
          </div>
          <input
            step="any"
            autoFocus="autofocus" 
            placeholder="0.000"
            className={`form-control font-2_25 ${
              stateParams.validationErrors?.amountError ? "is-invalid" : ""
            }`}
            required="required"
            type="number"
            value={stateParams.amount}
            onChange={e => handleAmountChange(e)}
            id="amount"
            disabled={stateParams.submitting == true ? true : false}
          />
        </div>
        <div className="font-0_95 text-muted mt-1">
          <span className="text-muted">
            Current Balance: {stateParams.balances[stateParams.selectedCurrency].toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            {" "}
            {stateParams.selectedCurrency}
          </span>
        </div>

        <div style={{display: "block", width: "100%"}}>
        {stateParams.error && (
          <span className="text-danger">
            {stateParams.error}
          </span>
        )}
        {stateParams.validationErrors?.amountError && (
          <span className="text-danger" style={{fontSize: "0.7rem"}}>
            {stateParams.validationErrors.amountError}
          </span>
        )}
        </div>

        <div style={{width: "100%"}}>
          <div className="mt-1 position-relative amount-slider-cont">
            <input
              type="range"
              min="0"
              max={stateParams.selectedCurrencyBalance > 0 ? stateParams.selectedCurrencyBalance : "0"}
              step="0.01"
              value={
                stateParams.amount === ""
                  ? "0"
                  : String(stateParams.amount).endsWith(".")
                  ? parseFloat(stateParams.amount) > 0
                    ? String(stateParams.amount).slice(0, -1)
                    : "0"
                  : stateParams.amount
              }
              className="form-range styled-range w-100"
              onChange={(e) =>
                handleAmountChange({ target: { value: parseFloat(e.target.value) } })
              }
              aria-label="Transfer Amount Slider"
              disabled={stateParams.submitting || stateParams.selectedCurrencyBalance <= 0 ||  (stateParams.amount && stateParams.amount.endsWith('.'))}
            />
            <div className="slider-labels d-flex justify-content-between mt-1">
              <span>0%</span>
              <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i>
              </span>
              <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i>
              </span>
              <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
              <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
              <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
              <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
              <span>
                Max:{" "}
                {stateParams.selectedCurrencyBalance.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                {stateParams.selectedCurrency}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="recipient-wrapper mt-5 mb-5">
        <WalletTransfer
          selectedTransferType={stateParams.selectedTransferType}
          submitting={stateParams.submitting}
          transferTypes={stateParams.transferTypes}
          destinationWallets={stateParams.destinationWallets}
          validationErrors={stateParams.validationErrors}
          recipient={stateParams.recipient}
          recipients={stateParams.recipients}
          lookedUpRecipient={stateParams.lookedUpRecipient}
          selectedDestinationWallet={stateParams.selectedDestinationWallet}
          handleChange={handleChange}
          handleRecipientChange={handleRecipientChange}
        />
      </div>
      <div className="form-group row mt-2">
        <label className="form-label">Description</label>
        <textarea
          className={
            stateParams.submitting
              ? "form-control pr-4 border-0"
              : "form-control pr-4"
          }
          onChange={e => handleChange(e, "note")}
          id="note"
          value={stateParams.note}
          disabled={stateParams.submitting}
        />
        <small id="noteHelpBlock" className="form-text text-muted">
          Enter your own description or message to your recipient (optional)
        </small>
      </div>
      <div className="form-group row mt-2">
        <label className="form-label">Reference</label>
        <input
          className={
            stateParams.submitting == true
              ? "form-control pr-4 border-0"
              : "form-control pr-4"
          }
          placeholder={stateParams.submitting ? stateParams.senderRef : ""}
          type="text"
          onChange={e => handleChange(e, "senderRef")}
          id="senderRef"
          value={stateParams.senderRef}
          disabled={stateParams.submitting == true ? true : false}
        />
        <small id="refHelpBlock" className="form-text text-muted">
          Use this field to add senderReference or Order ID/Number (optional)
        </small>
      </div>
      <Preview
        amount={stateParams.amount}
        fees={stateParams.fees}
        netAmount={stateParams.netAmount}
        selectedCurrency={stateParams.selectedCurrency}
        firstTimeWithdrawalFee={stateParams.firstTimeWithdrawalFee}
        isFirstTimeWithdrawal={propsParams.isFirstTimeWithdrawal}
      />
      <div className="text-muted font-0_7 mt-3 text-left">
        {stateParams.submitting ? (
          <div className="pt-4 mt-2">
            <i className="fa fa-exclamation-circle"></i> The quoted transfer
            fees are <strong>valid for the next 10 min</strong>. If your quote
            expires, simply start again. Click Submit to continue.
          </div>
        ) : (
          <div>
            <i className="fa fa-exclamation-circle"></i> Click Preview Transfer
            to preview your transfer including the applicable network fees.
          </div>
        )}
      </div>
      <hr />
      <Actions
        canSubmitTransfer={stateParams.canSubmitTransfer}
        submitting={stateParams.submitting}
        destinationAmount={stateParams.destinationAmount}
        transferId={stateParams.transferId}
        invalidTransfer={stateParams.invalidTransfer}
        cancelTransfer={cancelTransfer}
        refreshPage={refreshPage}
        submitTransfer={submitTransfer}
        submitTransferPreview={submitTransferPreview}
      />
    </div>
  </form>
);

export default CryptoForm;
