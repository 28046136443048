import React, { useCallback } from "react";
import Toggle from "react-toggle";
import Modal from "react-modal";
import { Oval } from "react-loader-spinner";
import card from "../images/Gold-Elegant-Business-Card.png";
import "react-toggle/style.css";
import "../styles/card.scss";
import { postRequest } from "../../../utils/httpRequest";
import toastr from "toastr";

function DebitCard({ card: cardDetail }) {
  toastr.options.positionClass = "toast-top-center";
  toastr.options.showDuration = "10000";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [cardNumber, setCardNumber] = React.useState("");
  const [activateModalIsOpen, setActivateModalIsOpen] = React.useState(false);
  const [cardDetailsModalIsOpen, setCardDetailsModalIsOpen] =
    React.useState(false);
  const [isActivatingCard, setIsActivatingCard] = React.useState(false);
  console.log(cardNumber);

  const submitCardActivation = useCallback(
    (e) => {
      setIsActivatingCard(true);
      postRequest("/api/v1/cards/activations", {
        card_number: cardNumber,
      })
        .then((response) => {
          setActivateModalIsOpen(false);
          toastr.success("Card activation request submitted successfully");
        })
        .catch((error) => {
          toastr.error(
            error?.data?.[0]?.detail ||
              error?.data?.error ||
              error?.data?.message ||
              "Something went wrong"
          );
        });
      setIsActivatingCard(false);
    },
    [setIsActivatingCard, cardNumber, toastr]
  );

  return (
    <div className="dbt-ctn">
      <div className="overlay">
        <p className="card-number">XXXX XXXX XXXX XXXX</p>
        <p className="expires-in">{cardDetail.status}</p>
        <p className="card-holder-name">{`${cardDetail.first_name} ${cardDetail.last_name}`}</p>
      </div>
      <img src={card} alt="" />
      <div className="card-actions">
        <div className="card-activation">
          {activateModalIsOpen == false && (
            <div className="activate-deactivate-card">
              <Toggle
                id={`card-status-toggle-${cardDetail.id}`}
                defaultChecked={cardDetail.status === "active"}
                disabled={["processed", "active", "completed"].includes(
                  cardDetail.status
                )}
                onChange={(e) => {
                  setActivateModalIsOpen(true);
                }}
              />
              <label htmlFor={`card-status-toggle-${cardDetail.id}`}>
                {cardDetail.status == "active" ? "Active" : "Activate"}
              </label>
            </div>
          )}
          {activateModalIsOpen == true && (
            <div className="oval-loader-container">
              <Oval
                visible={true}
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
                strokeWidth={5}
              />
            </div>
          )}
        </div>
        <div className="card-activation">
          {cardDetailsModalIsOpen == false && (
            <button
              type="button"
              className="btn btn-outline-primary mb-3"
              onClick={(e) => {
                setCardDetailsModalIsOpen(true);
              }}
            >
              View Card Details
            </button>
          )}
          {cardDetailsModalIsOpen == true && (
            <div className="oval-loader-container">
              <Oval
                visible={true}
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
                strokeWidth={5}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={cardDetailsModalIsOpen}
        style={customStyles}
        contentLabel="card-status-modal"
        shouldCloseOnOverlayClick={false}
        size="max-w-lg"
      >
        <h4>Card Details</h4>
        <div className="row">
          <div className="col-md-4">
            <div
              className="card"
              style={{ width: "18rem", backgroundColor: "rgba(0,0,0,.07)" }}
            >
              <div className="card-header">Personal Details</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  First Name: {cardDetail?.first_name}
                </li>
                <li className="list-group-item">
                  Last Name: {cardDetail?.last_name}
                </li>
                <li class="list-group-item">Title: {cardDetail?.title}</li>
                <li class="list-group-item">
                  Phone Number: {cardDetail?.phone_number}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="card"
              style={{ width: "18rem", backgroundColor: "rgba(0,0,0,.07)" }}
            >
              <div className="card-header">Means of Identification</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Passport Number: {cardDetail?.passport_number}
                </li>
                <li className="list-group-item">
                  Issuance Date: {cardDetail?.passport_issued_date}
                </li>
                <li className="list-group-item">
                  ID Type: {cardDetail?.id_type}
                </li>
                <li className="list-group-item">
                  Expiry Date: {cardDetail?.passport_expiry_date}
                </li>
                <li className="list-group-item">
                  Issuance Country: {cardDetail?.passport_issued_by}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div
              className="card"
              style={{ width: "18rem", backgroundColor: "rgba(0,0,0,.07)" }}
            >
              <div className="card-header">Card Mailing Address</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  Card Network: {cardDetail?.card_network}
                </li>
                <li className="list-group-item">
                  Card Type: {cardDetail?.card_type}
                </li>
                <li className="list-group-item">
                  Country: {cardDetail?.country}
                </li>
                <li className="list-group-item">State: {cardDetail?.state}</li>
                <li className="list-group-item">
                  Postal Code: {cardDetail?.postal_code}
                </li>
                <li className="list-group-item">
                  Address: {cardDetail?.address}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="btn btn-danger mb-3"
          onClick={(e) => setCardDetailsModalIsOpen(false)}
        >
          Close
        </button>
      </Modal>
      <Modal
        isOpen={activateModalIsOpen}
        style={customStyles}
        contentLabel="card-status-modal"
        shouldCloseOnOverlayClick={false}
        size="max-w-lg"
      >
        <h4>Activate Card</h4>
        <div className="mb-20" style={{ marginBottom: "20px" }}>
          <label htmlFor="card-number">Provide card number</label>
          <input
            id="card-number"
            className="form-control"
            type="text"
            placeholder="XXXX XXXX XXXX XXXX"
            aria-label="Search"
            value={cardNumber}
            onChange={(e) => {
              setCardNumber(e.target.value);
            }}
            style={{ width: "350px" }}
          />
        </div>
        <button
          type="button"
          className="btn btn-danger mb-3"
          onClick={(e) => setActivateModalIsOpen(false)}
          disabled={isActivatingCard}
        >
          Close
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          type="button"
          className="btn btn-primary mb-3"
          disabled={isActivatingCard}
          onClick={submitCardActivation}
        >
          {cardDetail.status === "active" ? "Deactivate" : "Activate"}
        </button>
        <br />
      </Modal>
    </div>
  );
}

export default DebitCard;
