import React, { Component } from "react";
import _ from "underscore";
import { getRequest, postRequest, patchRequest } from "../../utils/httpRequest";
import {
  validateWalletAddress,
  validateWalletAddressTag,
} from "../../model/walletAddress";
import AutoComplete from "../AutoComplete";
import { formErrors } from "../../utils/formErrors";
import CurrencySelect from "../Dashboard/CurrencySelect";
import CurrencyFlag from "react-currency-flags";

const transferTypes = [
  {
    label: "Recipient's Wallet",
    value: "recipient",
  },
  {
    label: "My Wallet",
    value: "my-wallet",
  },
];

class CryptoTransferUsdToUsd extends Component {
  constructor(props) {
    super(props);

    var formData = JSON.parse(sessionStorage.getItem("formData")) || {};

    this.state = {
      selectedSourceWallet: props.selectedSourceWallet,
      selectedDestinationWallet: "",
      sourceAmount: parseFloat(
        props.sourceAmount || formData?.amount || 0.0
      ).toFixed(2),
      destinationWallets: "",
      destinationCurrency: "USDT",
      destinationAmount:
        parseFloat(props.amount).toFixed(2) || parseFloat(0.0).toFixed(2),
      selectedCurrency: props.selectedSourceToken,
      selectedCurrencyBalance:
        parseFloat(props.selectedCurrencyBalance).toFixed(2) ||
        parseFloat(0.0).toFixed(2),
      alternateCurrency: props.alternateCurrency,
      alternateCurrencyBalance:
        parseFloat(props.alternateCurrencyBalance).toFixed(2) ||
        parseFloat(0.0).toFixed(2),
      alternateCurrencyRate: parseFloat(props.alternateCurrencyRate).toFixed(2),
      exchangeRate: props.exchangeRate,
      balances: props.balances,
      selectedTransferType: "recipient",
      recipient: props.recipient || "",
      recipientError: "",
      error: "",
      sourceAmountError: "",
      mode: "form",
      invalidTransfer: false,
      submitting: false,
      validationErrors: {},
      fees: parseFloat(0.0).toFixed(2),
      blockchainAddress: "",
      blockchainAddressTag: "",
      walletAddresses: props.walletAddresses || [],
      submitTransfer: false,
      selectedWalletAddress: "",
      note: props.note || formData?.description || "" || "",
      senderRef: props.senderRef || formData?.reference || "" || "",
      isStore: props.isStore || false,
      recipients: props.recipients,
      lookedUpRecipient: {},
      currentUserEmail: props.currentUserEmail,
    };
  }

  componentDidMount() {
    window.addEventListener("popstate", this.handleBackNavigation);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handleBackNavigation);
  }

  handleBackNavigation = (event) => {
    if (this.props.setSelectedPaymentMethod) {
      event.preventDefault();
      if (event.stopPropagation) {
        event.stopPropagation();
      }
      this.props.setSelectedPaymentMethod(null);
    }
  };

  getChainWalletAddress = (chain) => {
    getRequest(`/crypto_transfers/chain_wallet_addresses?chain=${chain}`).then(
      (addresses) => {
        const walletAddresses = addresses.map((address) => {
          return {
            label: address.wallet_label,
            address: address.address,
            addressTag: address.tag,
            value: address.id,
          };
        });

        this.setState({ walletAddresses });
      }
    );
  };

  handleChange = (event, key) => {
    this.setState({ [key]: event.target.value });

    if (key === "selectedTransferType") {
      this.setState({ invalidTransfer: false });
    }
  };

  handleRecipientChange = (value, userExists = false) => {
    const { validationErrors } = this.state;
    validationErrors.recipientError = "";
    this.setState({ invalidTransfer: false });

    this.setState({ recipient: value, recipientError: "" }, () => {
      if (!userExists) {
        this.lookupUser();
      }
    });
  };

  lookupUser = () => {
    const { validationErrors } = this.state;

    if (this.state.recipient) {
      if (this.state.recipient === this.state.currentUserEmail) {
        validationErrors.recipientError =
          "You cannot send to your own account.";

        this.setState({
          validationErrors,
          invalidTransfer: true,
        });
        return;
      }

      const data = {
        crypto_transfer: {
          recipient: this.state.recipient,
        },
      };

      const crsfToken = $('meta[name="csrf-token"]').attr("content");

      postRequest("/crypto_transfers/lookup_recipient", data, {
        "X-CSRF-Token": crsfToken,
      })
        .then((response) => {
          validationErrors.recipientError = "";
          this.setState({
            validationErrors,
            invalidTransfer: false,
            lookedUpRecipient: response,
          });
        })
        .catch((error) => {
          validationErrors.recipientError = error.data.error;

          this.setState({
            validationErrors,
            invalidTransfer: true,
          });
        });
    }
  };

  handleSourceAmountChange = (amount) => {
    //remove any letter other than numbers and decimals
    amount = amount.replace(/[^0-9.]/g, "");

    if (amount === "" || amount === "." || !amount) {
      this.setState({
        alternateAmount: "",
        sourceAmount: "",
        fees: 0,
        destinationAmount: 0,
      });
      return;
    }

    const calculateAlternateAmount =
      parseFloat(amount) * this.state.alternateCurrencyRate;
    this.setState(
      {
        sourceAmount: amount,
        alternateAmount: parseFloat(calculateAlternateAmount).toFixed(2),
        fees: 0,
        destinationAmount: 0,
      },
      () => this.validateSourceAmount()
    );
  };

  handleAlternateAmountChange = (amount) => {
    //remove any letter other than numbers and decimals
    amount = amount.replace(/[^0-9.]/g, "");

    if (amount === "" || amount === "." || !amount) {
      this.setState({ alternateAmount: "", sourceAmount: "" });
      return;
    }

    const calculateSourceAmount =
      parseFloat(amount) / this.state.alternateCurrencyRate;
    this.setState(
      {
        alternateAmount: amount,
        sourceAmount: parseFloat(calculateSourceAmount).toFixed(2),
      },
      () => this.validateSourceAmount()
    );
  };

  handleBlockchainAddressChange = (e) => {
    this.setState(
      {
        blockchainAddress: e.target.value,
      },
      () => this.validateBlockchainAddress()
    );
    this.validateBlockchainAddressTag();
  };

  handleBlockchainAddressTagChange = (e) => {
    this.setState({ blockchainAddressTag: e.target.value }, () =>
      this.validateBlockchainAddressTag()
    );
    this.validateBlockchainAddress();
  };

  handleSelectedWalletAddress = (e) => {
    this.setState({ selectedWalletAddress: e.target.value }, () =>
      this.setBlockchainAddress(e.target.value)
    );
    this.validateBlockchainAddress();
  };

  handleNoteChange = (e) => {
    this.setState({ note: e.target.value });
  };

  handleSenderRefChange = (e) => {
    this.setState({ senderRef: e.target.value });
  };

  setBlockchainAddress = (val) => {
    const { walletAddresses, validationErrors } = this.state;

    const walletAddress = _.find(walletAddresses, function (address) {
      return address.value === val;
    });

    if (undefined === walletAddress) {
      this.setState({ blockchainAddress: "" });
      this.setState({ blockchainAddressTag: "" });
      this.setState({ validationErrors: {} });
    } else {
      this.setState({ blockchainAddress: walletAddress.address });
      this.setState({ blockchainAddressTag: walletAddress.addressTag });
    }
  };

  validateBlockchainAddress = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddress = validateWalletAddress(chain, blockchainAddress);

    if (validAddress) {
      validationErrors.blockchainAddressError = "";
    } else {
      validationErrors.blockchainAddressError = "Address is invalid";
    }

    this.setState({ validationErrors });
  };

  validateBlockchainAddressTag = () => {
    const {
      selectedCurrency,
      blockchainAddress,
      blockchainAddressTag,
      validationErrors,
    } = this.state;
    const currency = selectedCurrency.toUpperCase();

    const chain = ["BTC", "AVAX", "XLM"].includes(currency) ? currency : "ETH";

    const validAddressTag = validateWalletAddressTag(
      chain,
      blockchainAddressTag
    );

    if (validAddressTag) {
      validationErrors.blockchainAddressTagError = "";
    } else {
      validationErrors.blockchainAddressTagError =
        "Address tag/memo is missing or not required";
    }

    this.setState({ validationErrors });
  };

  validateSourceAmount = () => {
    const { validationErrors } = this.state;

    if (Number(this.state.amount) < 0.01) {
      validationErrors.amountError =
        "Amount to transfer needs to be greater than or equal 0.01. Please enter a higher amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else if (
      Number(this.state.sourceAmount) >
      Number(this.state.selectedCurrencyBalance)
    ) {
      validationErrors.sourceAmountError =
        "Amount to transfer is greater than your available balance, please enter a lower amount.";

      this.setState({
        validationErrors,
        invalidTransfer: true,
      });
    } else {
      validationErrors.sourceAmountError = "";
      this.setState({
        validationErrors,
        invalidTransfer: false,
      });
    }
  };

  validateFormFields = () => {
    const { sourceAmount, recipient, selectedTransferType, blockchainAddress } =
      this.state;
    const { type } = this.props;

    let validationErrors = {};

    if (Number(sourceAmount) <= 0) {
      validationErrors.sourceAmountError = "Amount should be greater than zero";
    }

    if (
      type === "wallet" &&
      selectedTransferType === "recipient" &&
      !recipient
    ) {
      validationErrors.recipientError = "Recipient is required";
    }

    if (type === "blockchain" && !blockchainAddress) {
      validationErrors.blockchainAddressError =
        "Blockchain address is required";
    }

    return validationErrors;
  };

  refreshPage = (e) => {
    e.preventDefault();
    const url = window.location.href;
    window.location.assign(url);
  };

  setApiErrors = (errors) => {
    const formattedErrors = formErrors(errors);

    const { validationErrors } = this.state;

    const amountError = formattedErrors["gross_amount"];
    const recipientError = formattedErrors["destination_wallet_id"];

    validationErrors.sourceAmountError = amountError;
    validationErrors.recipientError = recipientError;

    return validationErrors;
  };

  submitTransferPreview = (e) => {
    const {
      selectedSourceWallet,
      selectedDestinationWallet,
      destinationWallets,
      destinationCurrency,
      recipient,
      sourceAmount,
      destinationAmount,
      fees,
      selectedCurrency,
      selectedTransferType,
      blockchainAddress,
      blockchainAddressTag,
      note,
      senderRef,
      selectedCurrencyBalance,
    } = this.state;

    e.preventDefault();

    const validationErrors = this.validateFormFields();

    if (!_.isEmpty(validationErrors)) {
      this.setState({ validationErrors, invalidTransfer: true });

      return;
    }

    this.setState({ submitting: true, error: "", validationErrors: {} });

    const data = {
      crypto_transfer: {
        total_type: "total_to_withdraw",
        source_wallet_id: selectedSourceWallet,
        recipient: selectedTransferType === "recipient" ? recipient : "",
        destination_wallet_id: selectedDestinationWallet,
        gross_amount: sourceAmount,
        // source_amount: sourceAmount,
        gross_amount_currency: "USDT",
        fees: fees,
        fees_currency: "USDT",
        net_amount_currency: "USDT",
        source_currency: selectedCurrency,
        destination_currency: "USDT",
        destination_type: this.props.type,
        blockchain_address: blockchainAddress,
        blockchain_address_tag: blockchainAddressTag,
        note: note,
        sender_ref: senderRef,
        selected_currency_balance: selectedCurrencyBalance,
        type: "CryptoTransfers::BcCryptoWithdrawal",
      },
      blockchain_not_needed: true,
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest("/crypto_transfers/send_crypto", data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        this.setState({
          exchangeRate: response.exchange_rate,
          fees: Number.parseFloat(response.fees),
          submitTransfer: true,
          transferId: response.transfer_id,
          expiresAt: response.expiresAt,
          destinationAmount: response.destination_amount,
          sourceAmount: response.source_amount,
          netAmount: response.source_amount,
          note: note,
        });
      })
      .catch((error) => {
        const estimate = error.data.estimate;

        this.setState({ error: error?.data?.error });

        const errors = this.setApiErrors(error?.data?.errors);

        this.setState({
          validationErrors: errors,
          submitting: false,
          fees:
            parseFloat(estimate?.fees || "0.0") +
            parseFloat(estimate?.first_time_withdrawal_fee || "0.0"),
          destinationAmount: parseFloat(estimate?.destination_amount || "0.0"),
        });
      });
  };

  submitTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    getRequest(`/crypto_transfers/${transferId}/resend_confirmation`).then(
      () => {
        window.location = `/crypto_transfers/${transferId}`;
      }
    );
  };

  cancelTransfer = (e) => {
    const { transferId } = this.state;

    e.preventDefault();

    const data = {
      crypto_transfer: {
        id: transferId,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(`/crypto_transfers/${transferId}/cancel`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then(() => {
        // window.location = `/crypto_transfers/`;
        this.refreshPage(e);
        // window.location = `/crypto_transfers/new_blockchain_to_usd_transfer?token=${selectedCurrency}`;
      })
      .catch((error) => {
        this.setState({ submitting: false });
        console.log(error);
      });
  };

  render() {
    const { sourceWallets, currencies, type } = this.props;
    var formData = JSON.parse(sessionStorage.getItem("formData")) || {};

    const {
      sourceAmount,
      alternateAmount,
      selectedDestinationWallet,
      destinationWallets,
      destinationCurrency,
      destinationAmount,
      selectedCurrencyBalance,
      alternateCurrency,
      alternateCurrencyBalance,
      alternateCurrencyRate,
      selectedTransferType,
      recipient,
      error,
      invalidTransfer,
      submitting,
      validationErrors,
      fees,
      submitTransfer,
      transferId,
      note,
      senderRef,
      recipients,
      lookedUpRecipient,
    } = this.state;

    return (
      <div className="col-md-12">
        <div className="store-cardheader flex-column align-items-start pt-4">
          <h5 className="card-title mb-0 font-weight-bold">
            Send to a BananaCrystal Account
          </h5>
          <p className="m-0 my-1 font-0_85">
            Send payments for FREE. Simply ask your receiver to provide their
            email associated with their BananaCrystal account, or their USDT
            address.
            <br/>
          </p>

        </div>
        <form
          className="form-horizontal transfer-form px-md-3"
          id="new_crypto_transfer"
        >
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group row required ">
                  <label className="form-label">Amount</label>
                  <div
                    className={`input-group mr-sm-2 ${
                      validationErrors?.sourceAmountError ? "is-invalid" : ""
                    }`}
                  >
                    <div className="input-group-prepend">
                      <div className="input-group-text selected-currency font-0_85 px-1">
                        <CurrencyFlag
                          className="mr-1"
                          currency="USD"
                          size="md"
                        />{" "}
                        USD
                      </div>
                    </div>
                    <input
                      autoFocus="autofocus"
                      placeholder="0.000"
                      className={`form-control font-1_4`}
                      required="required"
                      type="text"
                      value={sourceAmount}
                      onChange={(e) =>
                        this.handleSourceAmountChange(e.target.value)
                      }
                      id="amount"
                      disabled={submitting}
                    />
                  </div>

                  <div style={{ display: "block" }}>
                    {error && (
                      <small className="error text-danger font-weight-bold">
                        {error}
                      </small>
                    )}
                    {validationErrors?.sourceAmountError && (
                      <small className="error text-danger font-weight-bold">
                        {validationErrors.sourceAmountError}
                      </small>
                    )}
                    <div className="font-0_8 text-muted font-weight-bold mt-1">
                      <span className="text-muted">
                        Max: ${" "}
                        {Number(selectedCurrencyBalance).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                        {alternateCurrency !== "USD" && (
                          <span>
                            {" "}
                            / {alternateCurrency}{" "}
                            {Number(alternateCurrencyBalance).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </span>
                        )}
                      </span>
                    </div>

                    <small
                      id="amountHelpBlock"
                      className="form-text text-muted"
                    >
                      {/* Enter total transfer amount desired (USD) {selectedCurrency}. */}
                    </small>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group row required ">
                  <label className="form-label">Alternate Currency</label>
                  <div
                    className={`input-group mb-2 mr-sm-2 ${
                      validationErrors?.sourceAmountError ? "is-invalid" : ""
                    }`}
                  >
                    <div className="input-group-prepend">
                      <div className="input-group-text selected-currency font-1_4">
                        <CurrencySelect preferredCurrency={alternateCurrency} />
                      </div>
                    </div>
                    <input
                      placeholder="0.00"
                      className={`form-control font-1_4`}
                      required="required"
                      type="text"
                      value={alternateAmount}
                      onChange={(e) =>
                        this.handleAlternateAmountChange(e.target.value)
                      }
                      id="amount"
                      disabled={submitting == true ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div className="mt-3 mb-3 position-relative amount-slider-cont">
                <input
                  type="range"
                  min="0"
                  max={selectedCurrencyBalance > 0 ? selectedCurrencyBalance : 1}
                  step="0.01"
                  value={
                    sourceAmount.endsWith(".")
                      ? 
                       sourceAmount.slice(0, -1)
                        :  sourceAmount >  0 ? sourceAmount : "0"
                  }                  
                  className="form-range styled-range w-100"
                  onChange={(e) => this.handleSourceAmountChange(e.target.value)}
                  disabled={submitting || selectedCurrencyBalance <= 0}
                  />
                <div className="slider-labels d-flex justify-content-between mt-1">
                  <span>0%</span>
                  <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i>
                </span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i>
                </span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                  <span>Max: ${Number(selectedCurrencyBalance).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}</span>
                </div>
              </div>
            </div>
            <div>
              {type === "wallet" && (
                <div>
                  {destinationWallets.length > 0 && (
                    <div className="form-group row required mt-2 ">
                      <label className="form-label">
                        Select the crypto wallet
                      </label>
                      <select
                        className="form-control form-control-lg expand-select"
                        required="required"
                        value={selectedTransferType}
                        disabled={submitting}
                        onChange={(e) =>
                          this.handleChange(e, "selectedTransferType")
                        }
                      >
                        <option value="">-- Select the Wallet ---</option>
                        {transferTypes.map((transferType, i) => (
                          <option key={i} value={transferType.value}>
                            {transferType.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {(destinationWallets.length === 0 ||
                    selectedTransferType === "recipient") && (
                    <div className={`form-group row required recipient`}>
                      <label className="form-label">To:</label>
                      <AutoComplete
                        updateRecipient={this.handleRecipientChange}
                        placeholder="Enter your recipient's username or email"
                        className={`form-control py-2 text-purple `}
                        disabled={submitting}
                        suggestions={recipients}
                        lookedUpRecipient={lookedUpRecipient}
                        userInput={recipient || formData.to || ""}
                        validationError={validationErrors?.recipientError}
                      />
                      <div className="d-flex flex-column">
                        {validationErrors?.recipientError && (
                          <small className="error text-danger font-weight-bold">
                            {validationErrors.recipientError}
                          </small>
                        )}
                        <small
                          id="amountHelpBlock"
                          className="form-text text-muted"
                        >
                          Enter the username or the email of your recipient
                        </small>
                      </div>
                    </div>
                  )}
                  {selectedTransferType === "my-wallet" && (
                    <div className="form-group row required destination_wallet">
                      <label className="form-label">Destination wallet</label>
                      <select
                        className="form-control form-control-lg expand-select"
                        value={selectedDestinationWallet}
                        onChange={(e) =>
                          this.handleChange(e, "selectedDestinationWallet")
                        }
                      >
                        {destinationWallets.map((wallet, i) => (
                          <option key={i} value={wallet[1]}>
                            {wallet[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="form-group row mt-2">
              <label className="form-label">Description</label>
              <textarea
                className={
                  submitting
                    ? "form-control pr-4 border-0"
                    : "form-control pr-4"
                }
                placeholder={submitting ? note : ""}
                type="text"
                onChange={this.handleNoteChange}
                id="note"
                value={note}
                disabled={submitting}
              />
              <small id="noteHelpBlock" className="form-text text-muted">
                Enter your own description or message to your recipient
                (optional)
              </small>
            </div>
            <div className="form-group row mt-2">
              <label className="form-label">Reference</label>
              <input
                className={
                  submitting == true
                    ? "form-control pr-4 border-0"
                    : "form-control pr-4"
                }
                placeholder={submitting ? senderRef : ""}
                type="text"
                onChange={this.handleSenderRefChange}
                id="senderRef"
                value={senderRef}
                disabled={submitting}
              />
              <small id="refHelpBlock" className="form-text text-muted">
                Use this field to add senderReference or Order ID/Number
                (optional)
              </small>
            </div>
          </div>

          <div className="rounded-lg d-flex flex-row justify-content-around px-md-3 pb-3" style={{background: "#f9f8ff"}}>
            <div className="text-center">
              <p className="font-weight-bold">
                Amount{" "}
                <a
                  data-toggle="tooltip"
                  data-original-title="Amount the receiver will get"
                  title="Amount the receiver will get"
                >
                  <i className="fa fa-info-circle"></i>
                </a>
              </p>
              <p className="my-0">
                ${" "}
                {parseFloat(sourceAmount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
              {alternateCurrency !== "USD" && sourceAmount !== 0 && (
                <small>
                  {alternateCurrency}{" "}
                  {parseFloat(
                    alternateCurrencyRate * sourceAmount
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </small>
              )}
            </div>

            <div className="text-center">
              <p className="font-weight-bold">
                Fees{" "}
                <a
                  data-toggle="tooltip"
                  data-original-title="Transaction processing fees"
                  title="Transaction processing fees"
                >
                  <i className="fa fa-info-circle"></i>
                </a>
              </p>
              <p className="my-0">
                ${" "}
                {submitting
                  ? parseFloat(fees).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : parseFloat(fees).toFixed(2).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || "0.00"
                  }

              </p>
              {alternateCurrency !== "USD" && sourceAmount !== 0 && (
                <small>
                  {alternateCurrency}{" "}
                  {parseFloat(alternateCurrencyRate * fees).toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </small>
              )}
            </div>

            <div className="text-center">
              <p className="font-weight-bold">
                Total{" "}
                <a
                  data-toggle="tooltip"
                  data-original-title="Total amount to be deducted"
                  title="Total amount to be deducted"
                >
                  <i className="fa fa-info-circle"></i>
                </a>
              </p>
              <p className="my-0">
                {destinationCurrency === "USDT" ? "$" : destinationCurrency}{" "}
                {Number(destinationAmount)
                  ? parseFloat(destinationAmount)
                      .toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                  : "TBD"}
              </p>
              {alternateCurrency !== "USD" && Number(destinationAmount) > 0 && (
                <small>
                  {alternateCurrency}{" "}
                  {parseFloat(
                    alternateCurrencyRate * destinationAmount
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </small>
              )}
            </div>
          </div>

          <div className="my-2 ml-4 mx-2 preview-list">
            <div className="row subtotal">
              <div className="text-muted font-0_9 message">
                {submitting ? (
                  <div className="pt-4 mt-2">
                    <i className="fa fa-exclamation-circle"></i>{" "}
                    <strong>Valid for the next 10 min</strong>. If your
                    transaction expires, simply start again. Click Submit to
                    continue.
                  </div>
                ) : (
                  <div>
                    <i className="fa fa-exclamation-circle"></i> Click Preview
                    to preview your transaction.
                  </div>
                )}
              </div>
            </div>

            <hr />
          </div>
          <div className="form-actions">
            {submitTransfer && transferId ? (
              <div className="mr-auto">
                <div className="col-md-6 float-md-right pr-0">
                  <button
                    name="button"
                    type="submit"
                    className="btn btn-purple pull-right mb-3"
                    onClick={this.submitTransfer}
                  >
                    Submit <i className="fa fa-arrow-right"></i>
                  </button>
                </div>
                <div className="col-md-6 float-md-left pl-0">
                  <button
                    name="cancel"
                    type="submit"
                    className="btn pull-left mb-3"
                    onClick={this.cancelTransfer}
                    disabled={invalidTransfer}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="mr-auto">
                <div className="col-md-6 float-md-right pr-0">
                  <button
                    name="button"
                    type="submit"
                    className="btn store-paidbtn pull-right mb-2 col-xs-4"
                    onClick={this.submitTransferPreview}
                    disabled={invalidTransfer || submitting}
                  >
                    {submitting ? (
                      <>
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                        <span style={{ paddingLeft: "5px" }}>
                          Please Wait...Loading
                        </span>
                      </>
                    ) : (
                      <span>
                        Preview <i className="fa fa-arrow-right"></i>
                      </span>
                    )}
                  </button>
                </div>
                <div className="col-md-6 float-md-left pl-0">
                  <button
                    name="start_again"
                    type="submit"
                    className="btn pull-left mb-2 store-cancelbtn"
                    onClick={this.refreshPage}
                  >
                    <i class="fa fa-refresh"></i>
                    {" "}
                    Start Again 
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default CryptoTransferUsdToUsd;
