import React, { useEffect } from "react";

const CopyButtonListener = () => {
  useEffect(() => {
    const handleCopyButtonClick = (event) => {
      const button = event.target.closest(".btn-copy");
      if (button) {
        event.preventDefault();

        // Get the text to copy from the sibling span or data attribute
        const copyText = button.previousElementSibling?.innerText || button.getAttribute("data-copy-text");
        const copyText2 = button.previousElementSibling?.innerText || button.getAttribute("data-action");
        const copyText3 = button.previousElementSibling?.innerText || button.getAttribute("data-clipboard-text");

        if (copyText || copyText2 || copyText3) {
          // Create a temporary textarea to hold the text
          const textarea = document.createElement("textarea");
          textarea.value = copyText || copyText2 || copyText3 ;

          // Make the textarea readonly and hide it
          textarea.setAttribute("readonly", "");
          textarea.style.position = "absolute";
          textarea.style.left = "-9999px";

          document.body.appendChild(textarea);
          textarea.select();

          try {
            // Attempt to copy the text
            document.execCommand("copy");

            // Change button text or icon to indicate success
            const originalHTML = button.innerHTML;
            button.innerHTML = '&nbsp; <i class="fa fa-check"></i>'; // Change icon to checkmark
            button.disabled = true; // Optionally disable the button

            // Revert back after a delay
            setTimeout(() => {
              button.innerHTML = originalHTML;
              button.disabled = false;
            }, 2000); // Revert after 2 seconds
          } catch (err) {
            console.error("Failed to copy:", err);
          }

          // Remove the textarea
          document.body.removeChild(textarea);
        }
      }
    };

    // Add the event listener
    document.body.addEventListener("click", handleCopyButtonClick);

    // Cleanup the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener("click", handleCopyButtonClick);
    };
  }, []);

  return null; // This component doesn't render anything visible
};

export default CopyButtonListener;
