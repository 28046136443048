import React from "react";

const Referrals = ({ data }) => {
  const emailData = Object.entries(data.data).map(([email, value]) => ({
    email,
    value,
  }));

  return (
    <div
      style={{
        border: "1px solid gray",
        borderRadius: "8px",
        backgroundColor: "rgb(255, 245, 224)",
        borderColor: "rgb(47, 61, 98)",
        padding: "16px",
        marginBottom: "16px",
        minWidth: "90%",
        margin: "auto",
      }}
    >
      <h1 style={{ marginBottom: "8px", color: "rgb(47, 61, 98)" }}>
        Top Performing Referrers
      </h1>
      <div
        style={{
          height: "4px",
          backgroundColor: "#ff6384",
          marginBottom: "16px",
          borderRadius: "2px",
        }}
      ></div>
      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
        <li
          style={{
            marginBottom: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: "4px",
          }}
        >
          <strong>Total Referrers: </strong>
          <span style={{ color: "#555" }}>{emailData.length}</span>
        </li>
      </ul>
      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
        {emailData.map(({ email, value }, index) => (
          <li
            key={index}
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "4px",
            }}
          >
            <strong>{email}</strong>
            <span style={{ color: "#555" }}>{value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Referrals;
