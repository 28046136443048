import { formErrors } from "../../../../utils/formErrors";

export const validateAmount = (amount: number, balance) => {
  if (Number(amount) < 50) {
    return "Minimum amount is $50";
  }

  if (Number(amount) > Number(balance)) {
    return "Amount to load is greater than your available balance, please enter a lower amount.";
  }

  return "";
};

export const setApiErrors = (errors, validationErrors) => {
  const formattedErrors = formErrors(errors);

  validationErrors.amountError = formattedErrors["amount"];

  return validationErrors;
};
