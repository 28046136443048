import React from "react";

const Head = () => (
  <thead>
    <tr>
      <th></th>
      <th><i class="fas fa-info-circle" title="Date of activity."></i> Date</th>
      <th><i class="fas fa-info-circle" title="Transaction Amount."></i> Amount</th>
      <th><i class="fas fa-info-circle" title="The activity/transaction type."></i> Type</th>
      <th><i class="fas fa-info-circle" title="The description of the transaction."></i> Description</th>
      <th><i class="fas fa-info-circle" title="The status of the transaction."></i> Status</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
);

export default Head;
