import moment from "moment";

export const initialFiltersState = {
  startDate: moment("1990-01-01"),
  endDate: moment(),
  status: "",
  transferType: "",
  search: "",
  currency: "",
  currentPage: 0,
  totalPages: 0
};
