import React, { Component } from "react";
import moment from "moment";
import { getRequest, patchRequest } from "../../utils/httpRequest";
import Filters from "../AccountActivity/Filters";
import ActivityPagination from "../AccountActivity/ActivityPagination";
import SuccessDeleteActivityModal from './SuccessModal';
import DeleteActivityModal from './DeleteActivityModal';
import Table from "./Table";
import { initialFiltersState } from "./helpers";

class StoreActivities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transfers: [],
      filters: initialFiltersState,
      loading: true,
      modalButtonText: "PROCEED",
      pendingTransferId: "",
      currentPage: 1
    };
  }

  componentDidMount() {
    const { storeId } = this.props;
    getRequest(`/stores/${storeId}/history.json`).then(response => {
      this.setState({
        transfers: response.transfers,
        totalPages: response.total_pages,
        loading: false
      });
    });
  }

  onSelectFilter = (key, value) => {
    const { filters } = this.state;

    const updatedFilters = { ...filters, [key]: value };

    this.setState({ filters: updatedFilters });
  };

  handlePageChange = (e, currentPage) => {
    e.preventDefault();
    this.setState({ currentPage }, () => this.getTransfers());
  };

  filterTransfers = e => {
    e.preventDefault();
    this.setState({ loading: true, currentPage: 1 }, () => this.getTransfers());
  };

  getTransfers() {
    const {
      filters: { startDate, endDate, status, currency, transferType, search },
      currentPage
    } = this.state;

    const url =
      `/stores/${this.props.storeId}/history.json?q[created_at_gteq]=${startDate}&q[created_at_lteq]=${endDate}&q[external_status_cont]=${status}&` +
      `q[transfer_type]=${transferType}&q[search_term]=${search}&` +
      `q[source_currency_or_destination_currency_cont]=${currency}&page=${currentPage}`;

    getRequest(url).then(response => {
      this.setState({
        transfers: response.transfers,
        totalPages: response.total_pages,
        loading: false
      });
    });
  }

  clearFilters = e => {
    e.preventDefault();

    this.setState({ filters: initialFiltersState }, () =>
      this.filterTransfers(e)
    );
  };

  showDeleteModal = () => {
    const deleteModalCont = document.querySelector('#removeActivity-modal');
    if (deleteModalCont) {
      deleteModalCont.style.display = 'flex';
    }
  };

  closeModal = () => {
    const deleteModalCont = document.querySelector('#removeActivity-modal');
    if (deleteModalCont) {
      deleteModalCont.style.display = 'none';
    }
  };

  showSuccessModal = () => {
    const successModalCont = document.querySelector('#successmodal-storeactivity-cont');
    if (successModalCont) {
      successModalCont.style.display = 'flex';
    }
  };

  closeSuccessModal = () => {
    const successModalCont = document.querySelector('#successmodal-storeactivity-cont');
    if (successModalCont) {
      successModalCont.style.display = 'none';
    }
  };

  removeTransfer = (e, transferId) => {
    e.preventDefault();
    this.showDeleteModal(); 
    this.setState({
      pendingTransferId: transferId,
    })
  }
   
  proceedWithDeletion = (e) => {
    e.preventDefault();
    const deleteActivityProceedBtn = document.querySelector('#deleteActivityProceedBtn');
    this.setState({
      modalButtonText: "PROCEEDING"
    })
    
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    patchRequest(
      `/crypto_transfers/${this.state.pendingTransferId}/remove.json`,
      {},
      {
        "X-CSRF-Token": crsfToken
      }
    ).then(response => {
      const newTransfers = this.state.transfers.filter(
        transfer => transfer.id !== this.state.pendingTransferId
      );
      this.setState({
        transfers: newTransfers,
        pendingTransferId: "",
         modalButtonText: "PROCEED"
      });
      this.closeModal();
      this.showSuccessModal();
    })
  };

  render() {
    const { currencies, storeId } = this.props;
    const { transfers, currentPage, totalPages, filters, loading } = this.state;

    return (
      <div className="account-activity">
        <div id="successmodal-storeactivity-cont">
          <SuccessDeleteActivityModal
          action='Removed'
          closeModal={this.closeSuccessModal}
          />
        </div>
        <DeleteActivityModal 
          proceedWithDeletion={this.proceedWithDeletion}
          closeModal={this.closeModal}
          modalButtonText={this.state.modalButtonText}
        />
        <Filters
          currencies={currencies}
          filters={filters}
          filterTransfers={this.filterTransfers}
          onSelectFilter={this.onSelectFilter}
          clearFilters={this.clearFilters}
        />
        <Table
          transfers={transfers}
          removeTransfer={this.removeTransfer}
          storeId={storeId}
          loading={loading}
        />
        {totalPages > 1 && (
          <ActivityPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={this.handlePageChange}
          />
        )}
      </div>
    );
  }
}

export default StoreActivities;
