import React, { useEffect } from "react";
import { Option } from "react-select";
import { Field, FormikHelpers, FormikErrors } from "formik";
import SelectField from "../common/SelectField";
import FileField from "../common/FileField";
import DateTimeField from "../common/DateTimeField";
import { required } from "../../../../../utils/formValidations";
import { IconUserIdentification } from "../../../../Dashboard/dashboardIcons";
import countries from "../../../../../utils/countries.json";

interface Props {
  apiErrors?: any;
  errors: FormikErrors<{
    passport_number: string;
    passport_issued_date: string;
    passport_expiry_date: string;
    date_of_birth: string;
    place_of_birth: string;
    passport_bio_page: string;
    passport_with_selfie: string;
    digital_signature: string;
  }>;
  setFieldValue: (field: string, value: any) => void;
  validateForm: () => Partial<FormikHelpers<any>>;
  handleNextStepChange: (
    validateForm: () => Partial<FormikHelpers<any>>,
    step: string
  ) => void;
  handlePreviousStepChange: (step: string) => void;
}

const countryOptions = countries.map((country) => {
  return {
    value: country.code2,
    label: country.name,
  };
});

const IdentificationDetails: React.FC<Props> = ({
  errors,
  apiErrors,
  validateForm,
  setFieldValue,
  handleNextStepChange,
  handlePreviousStepChange,
}) => {
  useEffect(() => {
    // Initialize all tooltips
    ($('[data-toggle="tooltip"]') as any).tooltip();
  }, []);
  return (
    <>
      <div className="card-body">
        <div id="identification-details">
          <div className="w-100 d-flex align-items-center mb-3">
            <h4>
              <IconUserIdentification /> Identification Details
            </h4>
          </div>
          <div className="row card-deck mb-5">
            <form className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="date_of_birth">Date of Birth</label>
                  <Field
                    name="date_of_birth"
                    component={DateTimeField}
                    validate={required}
                    className={`form-control ${
                      errors.date_of_birth || apiErrors?.date_of_birth_error
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {errors.date_of_birth && !apiErrors?.date_of_birth_error && (
                    <small className="error">{errors.date_of_birth}</small>
                  )}
                  {apiErrors?.date_of_birth_error && (
                    <small className="error text-danger">
                      {apiErrors.date_of_birth_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="place_of_birth">Place Of Birth</label>
                  <Field
                    name="place_of_birth"
                    className={`form-control ${
                      errors.place_of_birth || apiErrors?.place_of_birth_error
                        ? "is-invalid"
                        : ""
                    }`}
                    component={SelectField}
                    validate={required}
                    options={countryOptions}
                    onChange={(option: Option) => {
                      setFieldValue("place_of_birth", option.value);
                    }}
                    error={
                      errors.place_of_birth || apiErrors?.place_of_birth_error
                    }
                  />
                  {errors.place_of_birth && !apiErrors?.place_of_birth && (
                    <small className="error">{errors.place_of_birth}</small>
                  )}
                  {apiErrors?.place_of_birth_error && (
                    <small className="error text-danger">
                      {apiErrors.place_of_birth_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="passport_number">Passport Number</label>
                  <Field
                    name="passport_number"
                    className={`form-control ${
                      errors.passport_number || apiErrors?.passport_number_error
                        ? "is-invalid"
                        : ""
                    }`}
                    validate={required}
                  />
                  {errors.passport_number &&
                    !apiErrors?.passport_number_error && (
                      <small className="error">{errors.passport_number}</small>
                    )}
                  {apiErrors?.passport_number_error && (
                    <small className="error text-danger">
                      {apiErrors.passport_number_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="passport_issued_date">
                    Passport Issue Date
                  </label>
                  <Field
                    name="passport_issued_date"
                    component={DateTimeField}
                    validate={required}
                    error={
                      errors.passport_issued_date ||
                      apiErrors?.passport_issued_date_error
                    }
                    className={`form-control ${
                      errors.passport_issued_date ||
                      apiErrors?.passport_issued_date_error
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {errors.passport_issued_date &&
                    !apiErrors?.passport_issued_date_error && (
                      <small className="error">
                        {errors.passport_issued_date}
                      </small>
                    )}
                  {apiErrors?.passport_issued_date_error && (
                    <small className="error text-danger">
                      {apiErrors.passport_issued_date_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="passport_expiry_date">
                    Passport Expiry Date
                  </label>
                  <Field
                    name="passport_expiry_date"
                    component={DateTimeField}
                    validate={required}
                    className={`form-control ${
                      errors.passport_expiry_date ||
                      apiErrors?.passport_expiry_date_error
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {errors.passport_expiry_date &&
                    !apiErrors?.passport_expiry_date_error && (
                      <small className="error">
                        {errors.passport_expiry_date}
                      </small>
                    )}
                  {apiErrors?.passport_expiry_date_error && (
                    <small className="error text-danger">
                      {apiErrors.passport_expiry_date_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="passport_bio_page">
                    Passport Bio Page{" "}
                    <i
                      data-toggle="tooltip"
                      data-placement="right"
                      className="fa fa-info-circle"
                      title="Please provide a clear image of your passport's bio data page showing your photo and personal information"
                    ></i>
                  </label>
                  <Field
                    name="passport_bio_page"
                    className={`form-control ${
                      errors.passport_bio_page ||
                      apiErrors?.passport_bio_page_error
                        ? "is-invalid"
                        : ""
                    }`}
                    component={FileField}
                    validate={required}
                  />
                  {errors.passport_bio_page &&
                    !apiErrors?.passport_bio_page_error && (
                      <small className="error">
                        {errors.passport_bio_page}
                      </small>
                    )}
                  {apiErrors?.passport_bio_page_error && (
                    <small className="error text-danger">
                      {apiErrors.passport_bio_page_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="passport_with_selfie">
                    Selfie with Passport Bio Page (Ensure non-mirror Image){" "}
                    <i
                      data-toggle="tooltip"
                      data-placement="right"
                      className="fa fa-info-circle"
                      title="Please take a clear photo of yourself holding your passport bio page. Make sure the image is not mirrored and all text is clearly readable."
                    ></i>
                  </label>
                  <Field
                    name="passport_with_selfie"
                    className={`form-control ${
                      errors.passport_with_selfie ||
                      apiErrors?.passport_with_selfie_error
                        ? "is-invalid"
                        : ""
                    }`}
                    component={FileField}
                    validate={required}
                  />
                  {errors.passport_with_selfie &&
                    !apiErrors?.passport_with_selfie_error && (
                      <small className="error">
                        {errors.passport_with_selfie}
                      </small>
                    )}
                  {apiErrors?.passport_with_selfie_error && (
                    <small className="error text-danger">
                      {apiErrors.passport_with_selfie_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="digital_signature">
                    Digital Signature{" "}
                    <i
                      data-toggle="tooltip"
                      data-placement="right"
                      className="fa fa-info-circle"
                      title="Please provide a clear image of your handwritten signature on a white background"
                    ></i>
                  </label>
                  <Field
                    name="digital_signature"
                    className={`form-control ${
                      errors.digital_signature ||
                      apiErrors?.digital_signature_error
                        ? "is-invalid"
                        : ""
                    }`}
                    component={FileField}
                    validate={required}
                  />
                  {errors.digital_signature &&
                    !apiErrors?.digital_signature_error && (
                      <small className="error">
                        {errors.digital_signature}
                      </small>
                    )}
                  {apiErrors?.digital_signature_error && (
                    <small className="error text-danger">
                      {apiErrors.digital_signature_error}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <button
            type="button"
            className="btn btn-outline-purple float-left"
            onClick={(e) => handlePreviousStepChange("personal")}
          >
            <i className="fa fa-arrow-left"></i> Previous
          </button>
          <button
            type="button"
            className="btn btn-purple float-right"
            onClick={(e) => handleNextStepChange(validateForm, "mailing")}
          >
            Next <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default IdentificationDetails;
