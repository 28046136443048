import React, { Component } from "react";
import { Formik, Form } from "formik";
import { postRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { formattedApiErrors, stepFromError } from "./helper";
import "./style.scss";

class Store extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: "one",
      apiErrors: {},
      submitting: false,
      validateStoreNameError: "",
      validateStoreUserNameError: "",
      validateStoreBusinessNameError: "",
    };
  }

  submit = values => {
    this.setState({ submitting: true });

    const { currentUser, referredByCode } = this.props;

    const {
      hearAboutUsQ,
      businessName,
      industry,
      name,
      userName,
      paymentEmail,
      supportEmail,
      address,
      phone,
      website,
      instructions,
      otherSoftware,
      enableNotifications,
      shoppingCartSoftwares
    } = values;

    const data = {
      name,
      industry,
      instructions,
      phone,
      business_name: businessName,
      hear_about_us: hearAboutUsQ,
      store_address: address,
      store_username: userName,
      store_support_email: supportEmail,
      store_payment_email: paymentEmail,
      receive_partner_emails: enableNotifications,
      primary_contact_name: currentUser.name,
      primary_contact_email: currentUser.email,
      store_url: website,
      referred_by_code: referredByCode,
      status: "active",
      default_lang: 'en',
      default_currency: 'USD',
      data: {
        shopping_cart_softwares: shoppingCartSoftwares,
        other_software: otherSoftware
      },
      store_users_attributes: [
        {
          user_id: currentUser.id,
          role: "owner"
        }
      ]
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest(`/stores.json`, data, {
      "X-CSRF-Token": crsfToken
    })
      .then(response => {
        window.location = `/stores/${response.id}/success`;
      })
      .catch(error => {
        const errors = formErrors(error.data.errors);

        this.setState({
          apiErrors: formattedApiErrors(errors),
          step: stepFromError(error.data.errors)
        });

        this.setState({ submitting: false });
      });
  };

  handlePreviousStepChange = step => {
    this.setState({ step });
  };

  handleNextStepChange = (validateForm, step) => {
    validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        this.setState({ step });
      }
    });
  };

  validateStoreName = (event) => {
  const forbiddenPattern = /(?:banana[\W_]*crystal|crystal[\W_]*banana|banana|bcrystal|bacrystal|bancrystal|banacrystal|banancrystal|bananacryst|banananacrysta|bananacry[\W_]*stal)/i;

  const name = event.target.value;
  const nextbutton = document.querySelector("#stepOneNextBtn");

  if (forbiddenPattern.test(name)) {
    // Show an error or prevent the change
    this.setState({
      validateStoreNameError: "Name must not include variations of 'BananaCrystal'."
    });
    nextbutton.disabled = true;
    return false;
  }

  // Call the actual handleChange if validation passes
  this.setState({validateStoreNameError: ""});
  nextbutton.disabled = false;
  return true;
};

validateStoreBusinessName = (event) => {
  const forbiddenPattern = /(?:banana[\W_]*crystal|crystal[\W_]*banana|banana|bcrystal|bacrystal|bancrystal|banacrystal|banancrystal|bananacryst|banananacrysta|bananacry[\W_]*stal)/i;

  const name = event.target.value;
  const nextbutton = document.querySelector("#stepOneNextBtn");

  if (forbiddenPattern.test(name)) {
    // Show an error or prevent the change
    this.setState({
      validateStoreBusinessNameError: "Name must not include variations of 'BananaCrystal'."
    });
    nextbutton.disabled = true;
    return false;
  }

  // Call the actual handleChange if validation passes
  this.setState({validateStoreBusinessNameError: ""});
  nextbutton.disabled = false;
  return true;
};

validateStoreUserName = (event) => {
  const forbiddenPattern = /(?:banana[\W_]*crystal|crystal[\W_]*banana|banana|bcrystal|bacrystal|bancrystal|banacrystal|banancrystal|bananacryst|banananacrysta|bananacry[\W_]*stal)/i;
  const nextbutton = document.querySelector("#stepOneNextBtn");

  const name = event.target.value;

  if (forbiddenPattern.test(name)) {
    // Show an error or prevent the change
    this.setState({
      validateStoreUserNameError: "Name must not include variations of 'BananaCrystal'."
    });
    nextbutton.disabled = true;
    return false;
  }

  // Call the actual handleChange if validation passes
  this.setState({validateStoreUserNameError: ""});
  nextbutton.disabled = false;
  return true;
};

  render() {
    const { step, apiErrors, submitting } = this.state;

    return (
      <div>
        <Formik
          initialValues={{
            referredByCode: this.props.referredByCode,
            hearAboutUsQ: "",
            businessName: "",
            industry: "",
            name: "",
            userName: "",
            paymentEmail: "",
            supportEmail: "",
            address: "",
            phone: "",
            website: "",
            instructions: "",
            otherSoftware: "",
            enableNotifications: true,
            shoppingCartSoftwares: []
          }}
          onSubmit={values => this.submit(values)}
        >
          {({ errors, values, handleChange, validateForm }) => (
            <Form className="form store-form">
              {step === "one" && (
                <StepOne
                  errors={errors}
                  apiErrors={apiErrors}
                  validateForm={validateForm}
                  handleChange={handleChange}
                  validateStoreNameError={this.state.validateStoreNameError}
                  validateStoreUserNameError={this.state.validateStoreUserNameError}
                  validateStoreName={this.validateStoreName}
                  validateStoreUserName={this.validateStoreUserName}
                  validateStoreBusinessName={this.validateStoreBusinessName}
                  validateStoreBusinessNameError={this.state.validateStoreBusinessNameError}
                  handleNextStepChange={this.handleNextStepChange}
                />
              )}
              {step === "two" && (
                <StepTwo
                  errors={errors}
                  apiErrors={apiErrors}
                  validateForm={validateForm}
                  handleChange={handleChange}
                  handlePreviousStepChange={this.handlePreviousStepChange}
                  handleNextStepChange={this.handleNextStepChange}
                />
              )}
              {step === "three" && (
                <StepThree
                  submitting={submitting}
                  errors={errors}
                  apiErrors={apiErrors}
                  values={values}
                  handleChange={handleChange}
                  handlePreviousStepChange={this.handlePreviousStepChange}
                  handleNextStepChange={this.handleNextStepChange}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default Store;
