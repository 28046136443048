import React, { useState } from "react";
import { Field, FormikHelpers, ErrorMessage } from "formik";
import { email, required } from "../../utils/formValidations";

const StepOne = ({
  errors,
  apiErrors,
  validateForm,
  handleChange,
  validateStoreNameError,
  validateStoreUserNameError,
  validateStoreName,
  validateStoreUserName,
  validateStoreBusinessName,
  validateStoreBusinessNameError,
  handleNextStepChange
}) => (
  <>
    <div className="form-group row required">
      <label htmlFor="hearAboutUsQ" className="form-label store-new-formlabel">
        How did you hear about us?
      </label>
      <Field
        id="hearAboutUsQ"
        name="hearAboutUsQ"
        className="form-control"
        validate={required}
        onChange={handleChange}
      />
      {errors.hearAboutUsQ && (
        <div className="error">{errors.hearAboutUsQ}</div>
      )}
      {apiErrors?.hearAboutUsError && (
        <div className="error text-danger">{apiErrors.hearAboutUsError}</div>
      )}
    </div>
    <div className="form-group row">
      <label htmlFor="referredByCode" className="form-label store-new-formlabel">
        Referral Code (Optional)
      </label>
      <Field
        id="referredByCode"
        name="referredByCode"
        className="form-control"
        onChange={handleChange}
      />
      {errors.referredByCode && (
        <div className="error">{errors.referredByCode}</div>
      )}
      {apiErrors?.referredByCode && (
        <div className="error text-danger">{apiErrors.referredByCode}</div>
      )}
    </div>
    <div className="form-group row required">
      <label htmlFor="businessName" className="form-label store-new-formlabel">
        Business Name
      </label>
      <p>Legal name of your business.</p>
      <Field
        id="businessName"
        name="businessName"
        className="form-control"
        validate={required}
        onChange={(event) => {
          if (validateStoreBusinessName(event)) {
            handleChange(event); // Only call handleChange if validation succeeds
          }
        }}
      />
      {validateStoreBusinessNameError && <div className="error">{validateStoreBusinessNameError}</div>}
      {errors.businessName && (
        <div className="error">{errors.businessName}</div>
      )}
      {apiErrors?.businessNameError && (
        <div className="error text-danger">{apiErrors.businessNameError}</div>
      )}
    </div>
    <div className="form-group row required">
      <label htmlFor="name" className="form-label store-new-formlabel">
        Store Name
      </label>
      <small>
        The name of your business as you want to appear for your customers when
        checking out.
      </small>
      <Field
        id="name"
        name="name"
        className="form-control"
        validate={required}
        onChange={(event) => {
          if (validateStoreName(event)) {
            handleChange(event); // Only call handleChange if validation succeeds
          }
        }}
      />
      {validateStoreNameError && <div className="error">{validateStoreNameError}</div>}
      {errors.name && <div className="error">{errors.name}</div>}
      {apiErrors?.nameError && (
        <div className="error text-danger">{apiErrors.nameError}</div>
      )}
    </div>
    <div className="form-group row required">
      <label htmlFor="userName" className="form-label store-new-formlabel">
        Username
      </label>
      <small>
        Use short usernames that include the name of your business Note: No
        spaces, only alpha numeric characters, underscores and dashes allowed.
      </small>
      <small>&nbsp; Customers will use your username to find your store/business.</small>
      <Field
        id="userName"
        name="userName"
        className="form-control"
        validate={required}
        onChange={(event) => {
          if (validateStoreUserName(event)) {
            handleChange(event); // Only call handleChange if validation succeeds
          }
        }}
      />
      {validateStoreUserNameError && <div className="error">{validateStoreUserNameError}</div>}
      {errors.userName && <div className="error">{errors.userName}</div>}
      {apiErrors?.storeUserNameError && (
        <div className="error text-danger">{apiErrors.storeUserNameError}</div>
      )}
    </div>
    <div className="form-group row">
      <label htmlFor="industry" className="form-label store-new-formlabel">
        Industry or Sector
      </label>
      <small>Describe your business industry or sector.</small>
      <Field
        as="textarea"
        id="industry"
        name="industry"
        className="form-control"
        onChange={handleChange}
      />
      {errors.industry && <div className="error">{errors.industry}</div>}
      {apiErrors?.industryError && (
        <div className="error text-danger">{apiErrors.industryError}</div>
      )}
    </div>

    <div className="action">
      <button
        type="button"
        id="stepOneNextBtn"
        className="btn store-paidbtn left"
        onClick={e => handleNextStepChange(validateForm, "two")}
      >
        Next
        &nbsp;
        <i className="fas fa-arrow-right"></i>
      </button>
    </div>
  </>
);

export default StepOne;
