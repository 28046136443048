import React, { useEffect } from "react";

const Signup = () => {
  useEffect(() => {
    const firstNameField = document.getElementById("first_name");
    const firstNameHint = document.getElementById("first-name-hint");
    const lastNameField = document.getElementById("last_name");
    const lastNameHint = document.getElementById("last-name-hint");
    const emailField = document.getElementById("email");
    const emailHint = document.getElementById("email-hint");
    const referralCodeField = document.getElementById("referral_code");
    const referralCodeHint = document.getElementById("referral-code-hint");
    const passwordField = document.getElementById("password");
    const confirmPasswordField = document.getElementById("password_confirmation");
    const confirmPasswordHint = document.getElementById("password-match-hint");
    const submitButton = document.getElementById("signup-submit-button");
    const togglePasswordIcon = document.getElementById("signup-toggle-password");
    const toggleConfirmPasswordIcon = document.getElementById("signup-confirm-toggle-password");
    
    const nameValidationRegex = /^[A-Z][a-zA-Z-]{1,}$/;
    const emailValidationRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const referralCodeRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]*$/;
    const passwordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[^\s]{8,}$/;
    const forbiddenPattern = /banana[\s\W_]*crystal|crystal[\s\W_]*banana|banana|banan|bcrystal|bacrystal|bancrystal|banacrystal|banancrystal|bananacryst|banananacrysta/i;

    // Disable submit button by default
    if (submitButton) {
      submitButton.disabled = true;
    }
    
    const validateName = (field, hint) => {
      const name = field.value;
      
      if (!nameValidationRegex.test(name)) {
        hint.textContent = "Name must start with an uppercase letter and contain only letters and hyphens.";
        hint.style.color = "red";
      } else if (forbiddenPattern.test(name)) {
        hint.textContent = "Name must not contain 'Banana Crystal' in any form.";
        hint.style.color = "red";
      } else {
        hint.textContent = "Name is valid.";
        hint.style.color = "green";
      }
      checkButtonState();
    };

    if (window.location.pathname === "/users") {
      history.replaceState({}, "", "/users/sign_up");
    }

    const checkButtonState = () => {
      const isFirstNameValid = firstNameField?.value.length >= 2 && nameValidationRegex.test(firstNameField.value);
      const isLastNameValid = lastNameField?.value.length >= 2 && nameValidationRegex.test(lastNameField.value);
      const isEmailValid = emailValidationRegex.test(emailField?.value);
      const isPasswordValid = passwordValidationRegex.test(passwordField?.value);
      const isConfirmPasswordValid = confirmPasswordField?.value === passwordField?.value;
      const forbiddenPatternDetectedFirstName = forbiddenPattern.test(firstNameField?.value);
      const forbiddenPatternDetectedLastName = forbiddenPattern.test(lastNameField?.value);

      // Enable the submit button only if all fields are valid
      if (submitButton) {
        submitButton.disabled = !(!forbiddenPatternDetectedFirstName && !forbiddenPatternDetectedLastName && isFirstNameValid && isLastNameValid && isPasswordValid && isConfirmPasswordValid);
      }
    };

    const validateEmail = (field, hint) => {
      const email = field.value;
      if (emailValidationRegex.test(email) && !field.value.toLowerCase().endsWith(".exe")) {
        hint.textContent = "Email is valid.";
        hint.style.color = "green";
      } else {
        hint.textContent = "Email must follow the valid format and not end with '.exe'.";
        hint.style.color = "red";
      }
    };

    const validateReferralCode = (field, hint) => {
      const code = field.value;
      if (referralCodeRegex.test(code)) {
        hint.textContent = "Referral code is valid.";
        hint.style.color = "green";
      } else {
        hint.textContent = "Referral code can only contain letters and numbers.";
        hint.style.color = "red";
      }
    };

    const validatePassword = () => {
      const password = passwordField.value;
      const passwordHint = document.getElementById("password-hint"); // Add this line to ensure the password hint exists
  
      if (passwordValidationRegex.test(password)) {
        passwordHint.textContent = "Password is valid.";
        passwordHint.style.color = "green";
      } else {
        passwordHint.textContent = "Password must be at least 8 characters long, include uppercase, lowercase, a number, a special character, and should not contain spaces or periods.";
        passwordHint.style.color = "red";
      }
      validatePasswordConfirmation();
      checkButtonState();
    };

    const validatePasswordConfirmation = () => {
      if (confirmPasswordField.value === passwordField.value) {
        confirmPasswordHint.textContent = "Passwords match.";
        confirmPasswordHint.style.color = "green";
      } else {
        confirmPasswordHint.textContent = "Passwords do not match.";
        confirmPasswordHint.style.color = "red";
      }
      checkButtonState();
    };

    const restrictInput = (event, regex) => {
      const char = String.fromCharCode(event.which);
      if (!regex.test(char)) {
        event.preventDefault();
      }
    };

    const restrictEmailInput = (event) => {
      const char = String.fromCharCode(event.which);
      const allowedEmailChars = /^[a-zA-Z0-9@._-]+$/;
      if (!allowedEmailChars.test(char) || (emailField.value.length === 0 && (char === '.' || char === '-'))) {
        event.preventDefault();
      }
    };

    const removeForbiddenEmailParts = () => {
      emailField.value = emailField.value.replace(/\.exe/gi, '');
      validateEmail(emailField, emailHint);
    };

    const preventPaste = (event) => {
      event.preventDefault();
      alert("Pasting is not allowed in this field.");
    };

    const saveToLocalStorage = () => {
      const now = new Date().getTime();
      const data = {
        firstName: firstNameField?.value,
        lastName: lastNameField?.value,
        referralCode: referralCodeField?.value,
        email: emailField?.value,
        timestamp: now,
      };
      localStorage.setItem("formData", JSON.stringify(data));
    };

    const loadFromLocalStorage = () => {
      const savedData = JSON.parse(localStorage.getItem("formData"));
      if (savedData) {
        const now = new Date().getTime();
        if (now - savedData.timestamp < 24 * 60 * 60 * 1000) {
          if (firstNameField) firstNameField.value = savedData.firstName || "";
          if (lastNameField) lastNameField.value = savedData.lastName || "";
          if (referralCodeField) referralCodeField.value = savedData.referralCode || "";
          if (emailField) emailField.value = savedData.email || "";
          checkButtonState();
        } else {
          localStorage.removeItem("formData");
        }
      }
    };

    const handleToggle = () => {
      if (passwordField.type === "password") {
        passwordField.type = "text";
        togglePasswordIcon.classList.remove("fa-eye-slash");
        togglePasswordIcon.classList.add("fa-eye");
      } else {
        passwordField.type = "password";
        togglePasswordIcon.classList.remove("fa-eye");
        togglePasswordIcon.classList.add("fa-eye-slash");
      }
    }

    const handleConfirmToggle = () => {
      if (confirmPasswordField.type === "password") {
        confirmPasswordField.type = "text";
        toggleConfirmPasswordIcon.classList.remove("fa-eye-slash");
        toggleConfirmPasswordIcon.classList.add("fa-eye");
      } else {
        confirmPasswordField.type = "password";
        toggleConfirmPasswordIcon.classList.remove("fa-eye");
        toggleConfirmPasswordIcon.classList.add("fa-eye-slash");
      }
    }

    // Load data from local storage on mount
    loadFromLocalStorage();

    // Add event listeners
    if (firstNameField) {
      firstNameField.addEventListener("input", () => validateName(firstNameField, firstNameHint));
      firstNameField.addEventListener("keypress", (event) => restrictInput(event, /^[a-zA-Z-]$/));
      firstNameField.addEventListener("paste", preventPaste);
    }

    if (lastNameField) {
      lastNameField.addEventListener("input", () => validateName(lastNameField, lastNameHint));
      lastNameField.addEventListener("keypress", (event) => restrictInput(event, /^[a-zA-Z-]$/));
      lastNameField.addEventListener("paste", preventPaste);
    }

    if (emailField) {
      emailField.addEventListener("input", () => validateEmail(emailField, emailHint));
      emailField.addEventListener("keypress", restrictEmailInput);
      emailField.addEventListener("keyup", removeForbiddenEmailParts);
      emailField.addEventListener("paste", (event) => {
        const pasteData = event.clipboardData.getData("text");
        if (!emailValidationRegex.test(pasteData) || pasteData.toLowerCase().endsWith(".exe") || /^[.-]/.test(pasteData)) {
          event.preventDefault();
          alert("Pasting invalid email format or '.exe' ending is not allowed.");
        }
      });
    }

    if (referralCodeField) {
      referralCodeField.addEventListener("input", () => validateReferralCode(referralCodeField, referralCodeHint));
      referralCodeField.addEventListener("keypress", (event) => restrictInput(event, /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]*$/));
      // referralCodeField.addEventListener("paste", preventPaste);
    }

    if (confirmPasswordField && passwordField) {
      confirmPasswordField.addEventListener("input", validatePasswordConfirmation);
      passwordField.addEventListener("input", validatePassword);
      passwordField.addEventListener("keypress", (event) => restrictInput(event, /^[^\s._<>%]*$/));
      confirmPasswordField.addEventListener("paste", preventPaste);
      passwordField.addEventListener("paste", preventPaste);
      togglePasswordIcon.addEventListener("click", handleToggle);
      toggleConfirmPasswordIcon.addEventListener("click", handleConfirmToggle);
    }

    // Save data on form submission
    const form = document.querySelector("form");
    form?.addEventListener("submit", saveToLocalStorage);

    // Cleanup event listeners on unmount
    return () => {
      if (firstNameField) {
        firstNameField.removeEventListener("input", () => validateName(firstNameField, firstNameHint));
        firstNameField.removeEventListener("keypress", (event) => restrictInput(event, /^[a-zA-Z-]$/));
        firstNameField.removeEventListener("paste", preventPaste);
      }
      if (lastNameField) {
        lastNameField.removeEventListener("input", () => validateName(lastNameField, lastNameHint));
        lastNameField.removeEventListener("keypress", (event) => restrictInput(event, /^[a-zA-Z-]$/));
        lastNameField.removeEventListener("paste", preventPaste);
      }
      if (emailField) {
        emailField.removeEventListener("input", () => validateEmail(emailField, emailHint));
        emailField.removeEventListener("keypress", restrictEmailInput);
        emailField.removeEventListener("keyup", removeForbiddenEmailParts);
      }
      if (referralCodeField) {
        referralCodeField.removeEventListener("input", () => validateReferralCode(referralCodeField, referralCodeHint));
        referralCodeField.removeEventListener("keypress", (event) => restrictInput(event, /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]*$/));
        // referralCodeField.removeEventListener("paste", preventPaste);
      }
      if (confirmPasswordField) {
        confirmPasswordField.removeEventListener("input", validatePasswordConfirmation);
        passwordField.removeEventListener("input", validatePassword);
        passwordField.removeEventListener("keypress", (event) => restrictInput(event, /^[^\s.<>%]*$/));
        confirmPasswordField.removeEventListener("paste", preventPaste);
        togglePasswordIcon.removeEventListener("click", handleToggle);
        toggleConfirmPasswordIcon.removeEventListener("click", handleConfirmToggle);
      }
      form?.removeEventListener("submit", saveToLocalStorage);
    };
  }, []);

  return null;
};

export default Signup;
