import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getRequest } from "../../../../../utils/httpRequest";
import Filter from "bad-words";

const OtherSettings = ({ updateParent, getData, handleProfanityDetection, handleForbiddenOfferNameDetection, handleForbiddenOfferLabelDetection }) => {
  const [predefinedTags, setPredefinedTags] = useState([]);
  const [profanityError, setProfanityError] = useState({});
  const [offerNameError, setOfferNameError] = useState("");
  const [offerLabelError, setOfferLabelError] = useState("");

  const getTags = () => {
    getRequest("/p2p_trades/tags").then((response) => {
      const tags = response.map((tag) => ({
        label: tag.name,
        value: tag.slug,
      }));

      setPredefinedTags(tags);
    });
  };

  useEffect(() => {
    getTags();
  }, []);

  const filter = new Filter();

  const handleInputChange = (field, value) => {
    if (field === "offerTerms" || field === "customOfferLabel") {
      if (filter.isProfane(value)) {
        setProfanityError((prevErrors) => ({
          ...prevErrors,
          [field]: true,
        }));
        handleProfanityDetection(true);
        return;
      }
    }

    setProfanityError((prevErrors) => ({
      ...prevErrors,
      [field]: false,
    }));

    const anyProfanityDetected = Object.values(profanityError).some(
      (error) => error
    );
    handleProfanityDetection(anyProfanityDetected);

    updateParent(field, value);
  };

  const validateOfferName = (event) => {
    const forbiddenPattern = /(?:banana[\W_]*crystal|crystal[\W_]*banana|banana|bcrystal|bacrystal|bancrystal|banacrystal|banancrystal|bananacryst|banananacrysta|bananacry[\W_]*stal)/i;  
    const name = event.target.value;
  
    if (forbiddenPattern.test(name)) {
      // Show an error or prevent the change
      setOfferNameError("Name must not include variations of 'BananaCrystal'.");
      handleForbiddenOfferNameDetection(true);
      return false;
    }
  
    setOfferNameError("");
    handleForbiddenOfferNameDetection(false);
    return true;
  };
  
  const validateOfferLabel = (event) => {
    const forbiddenPattern = /(?:banana[\W_]*crystal|crystal[\W_]*banana|banana|bcrystal|bacrystal|bancrystal|banacrystal|banancrystal|bananacryst|banananacrysta|bananacry[\W_]*stal)/i;  
    const name = event.target.value;
  
    if (forbiddenPattern.test(name)) {
      // Show an error or prevent the change
      setOfferLabelError("Name must not include variations of 'BananaCrystal'.");
      handleForbiddenOfferLabelDetection(true);
      return false;
    }
  
    setOfferLabelError("");
    handleForbiddenOfferLabelDetection(false);
    return true;
  };

  return (
    <div className="createoffer-othersettings">
      <div className="mb-3">
        <h4 className="createoffer-paymentmethod-labels">Offer Instructions</h4>
      </div>
      <div className="offerinstructions-form">
        <div>
          <div>
            <form className="col-md-12 px-0">
              <div className="form-group ">
                <div style={{ width: "100%" }}>
                  <strong>
                    <label htmlFor="exampleInputEmail1" className="createoffer-sublabels">
                      Offer Tags <span style={{ color: "red" }}>*</span>
                    </label>
                  </strong>
                  <Select
                    options={predefinedTags}
                    isMulti={true}
                    placeholder="Select or start typing to search"
                    value={getData("tags") || null}
                    onChange={(items) => {
                      updateParent("tags", items);
                    }}
                  />
                  <p id="emailHelp" className="form-text text-muted">
                    Select at least one tag that best describes your offer terms.
                  </p>
                </div>
              </div>
              <div className=" form-group">
                <strong>
                  <label htmlFor="exampleInputPassword1" className="createoffer-sublabels">
                    Offer Name <span style={{ color: "red" }}>*</span>
                  </label>
                </strong>
                <input
                  type="text"
                  className={`form-control ${
                    profanityError.customOfferLabel ? "is-invalid" : ""
                  }`}
                  id="exampleInputPassword1"
                  placeholder="Maximum 25 characters. Only letters, numbers and dashes."
                  maxLength={25}
                  defaultValue={getData("customOfferLabel") || ""}
                  onChange={(event) => {
                    if (validateOfferName(event)) {
                      handleInputChange("customOfferLabel", event.target.value); // Only call handleChange if validation succeeds
                    }
                  }}
                />
                {offerNameError &&  <div className="createoffer-error-alert">
                    {offerNameError}
                  </div>}
                {profanityError.customOfferLabel && (
                  <div className="createoffer-error-alert">
                    Profanity detected in offer label.
                  </div>
                )}
                <p id="emailHelp" className="form-text text-muted">
                  Make your offer stand out to other users with a catchy name.
                  Your offer name can be up to 25 characters long and can
                  contain letters, numbers and the hyphen.
                </p>
              </div>
              <div className=" form-group">
                <strong>
                  <label htmlFor="exampleInputPassword1" className="createoffer-sublabels">
                    Offer Terms <span style={{ color: "red" }}>*</span>
                  </label>
                </strong>
                <textarea
                  className={`form-control ${
                    profanityError.offerTerms ? "is-invalid" : ""
                  } createoffer-input`}
                  id="exampleInputPassword1"
                  placeholder="Write your terms here."
                  defaultValue={getData("offerTerms") || ""}
                  onChange={(event) => {
                    if (validateOfferLabel(event)) {
                      handleInputChange("offerTerms", event.target.value); // Only call handleChange if validation succeeds
                    }
                  }}
                ></textarea>
                                {offerLabelError &&  <div className="createoffer-error-alert">
                    {offerLabelError}
                  </div>}
                {profanityError.offerTerms && (
                  <div className="invalid-feedback createoffer-error-alert">
                    Profanity detected in offer terms.
                  </div>
                )}
                <p id="emailHelp" className="form-text text-muted">
                  Anybody who views your offer will see these terms. Keep them
                  simple and clear to make your offer sound attractive.
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherSettings;
