import React, { FC } from "react";
import HitComponent from "./HitComponent";
import NoHits from "./NoHits";
import Pagination from "./Pagination";
import { OfferType, ReviewType } from "../Types";

interface Props {
  offers: OfferType[];
  reviews: ReviewType[];
  currentExternalProfileId: string;
  changeOfferStatus: (offer: OfferType, status: boolean) => void;
  handlePageChange: (page: number) => void;
  totalPages: number;
  currentPage: number;
}

const Content: FC<Props> = ({
  offers,
  reviews,
  currentExternalProfileId,
  changeOfferStatus,
  handlePageChange,
  totalPages,
  currentPage,
}) => (
  <div className="tab-content" id="offers-tab-content">
    <div className="tab-pane fade show active" id="tab1">
      <table className="table offer-list-table table-heading">
        <thead className="offer-list-table-head">
          <tr className="text-muted">
            <th className="head-profilesection">Advertiser</th>
            <th className="head-offers-table-cols">Price</th>
            <th className="head-offers-table-cols">Available/Order Limit</th>
            <th className="head-offers-table-cols">Payment Method</th>
            <th className="head-offers-table-cols align-right">Trade</th>
          </tr>
        </thead>
      </table>
      {offers?.length > 0 ? (
        offers.map((offer) => (
          <HitComponent
            key={offer.id}
            currentExternalProfileId={currentExternalProfileId}
            changeOfferStatus={changeOfferStatus}
            hit={offer}
            reviews={reviews}
          />
        ))
      ) : (
        <NoHits />
      )}
    </div>
    {/* Combined Pagination Controls */}
    <Pagination
      onPageChange={handlePageChange}
      totalPages={totalPages}
      currentPage={currentPage}
    />
  </div>
);

export default Content;
