import React, { FC, useState } from "react";
import UsersOnlineStatus from "../../../../UsersOnlineStatus";
import { abbreviateNumber } from "../../../Trades/CreateTrade/helpers";
import Switch from "react-switch";
import { getRating, getTotalRating } from "../helpers";
import { OfferType, ReviewType } from "../Types";

interface Props {
  hit: OfferType;
  currentExternalProfileId: string;
  changeOfferStatus: (offer: OfferType, status: boolean) => void;
  reviews: ReviewType[];
}

const HitComponent: FC<Props> = ({
  hit,
  currentExternalProfileId,
  changeOfferStatus,
  reviews,
}) => {
  const [expandedOffers, setExpandedOffers] = useState([]);

  if (!hit || !hit.cryptoCurrency || !hit.fiatCurrency || !hit.paymentMethod) {
    console.error("Incomplete data received:", { hit });

    return <div></div>;
  }

  let tags = [];
  try {
    tags = hit.tags ? JSON.parse(hit.tags) : [];
  } catch (e) {
    console.error("Failed to parse tags JSON:", e);
    tags = [];
  }

  const toggleDetails = (index) => {
    setExpandedOffers((prevExpandedOffers) => {
      const newExpandedOffers = [...prevExpandedOffers];
      if (newExpandedOffers.includes(index)) {
        newExpandedOffers.splice(newExpandedOffers.indexOf(index), 1);
      } else {
        newExpandedOffers.push(index);
      }
      return newExpandedOffers;
    });
  };

  const calculateMinUSD = () => {
    const tradeRate = Number(hit.tradeRate);
    const rangeMin = Number(hit.rangeMin);
  
    const result = tradeRate !== 0 ? rangeMin / tradeRate : 0;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(result || 0);
  };
  
  const calculateMaxUSD = () => {
    const tradeRate = Number(hit.tradeRate);
    const rangeMax = Number(hit.rangeMax);
  
    const result = tradeRate !== 0 ? rangeMax / tradeRate : 0;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(result || 0);
  };

  const getInitialLetter = () => {
    return hit.customOfferLabel
      ? hit.customOfferLabel.charAt(0).toUpperCase()
      : "";
  };

  return (
    <div className="hit-content">
      <div className="table-container">
        <table className="table table-hover offer-list-table">
          <tbody className="offer-list-table-body">
            <tr id={`offer_${hit.id}`} className="offer-list-table-body-row">
              <td className="profilesection">
                <div className="d-flex profile-head">
                  <div
                    className="avatar-default"
                    style={{
                      backgroundColor:
                        hit.offerTypeField === "sell"
                          ? "#4c3f84dc"
                          : "#5256a3a6;",
                    }}
                  >
                    {getInitialLetter()}
                  </div>
                  <div className="offer_name pl-2 d-block">
                    {currentExternalProfileId === hit.profile?.id && (
                      <div className="profile-myoffer">
                        <i className="fa fa-star"></i> My Offer
                      </div>
                    )}
                    <div
                      className="offer-display-nickname"
                      title={`${hit?.customOfferLabel}`}
                    >
                      <a
                        href={`/profile/${hit?.profile?.userName}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {hit?.customOfferLabel.length > 15
                          ? `${hit?.customOfferLabel.slice(0, 15)}...`
                          : `${hit?.customOfferLabel.slice(0, 15)}`}{" "}
                      </a>
                      &nbsp;
                      <i
                        title="Verified Broker"
                        className="fa fa-check-circle"
                        style={{ color: "#daf7a6" }}
                      ></i>
                    </div>
                    <div className="font-0_3">
                      <UsersOnlineStatus userId={hit?.profile?.userId} />
                    </div>
                    <div className="profile-more-details">
                      <>
                        <span title="Total trades completed">
                          {abbreviateNumber(hit?.profile?.totalTrades || 0)}{" "}
                          Trades
                        </span>
                        <span className="offer-profiledetails-divider">
                          &nbsp;|&nbsp;
                        </span>
                        {/* <div>Trade Volume: {hit?.profile?.tradeVolume}</div> */}
                        {/* <span>{hit?.profile?.trustedByCount} Reviews </span> */}
                        <span title="Rating on previous trades">
                          <i className="fa fa-thumbs-up profiledetails-icon"></i>
                          &nbsp;
                          {reviews
                            ? `${getRating(hit?.profile?.id, reviews)}`
                            : "..."}
                          <span className="offer-profiledetails-divider">
                          <br/>
                          </span>
                        </span>

                        <span title="Total reviews received by broker">
                        <i className="fa fa-comments profiledetails-icon"></i> Reviews: 
                          &nbsp;
                          {reviews
                            ? `${getTotalRating(hit?.profile?.id, reviews)} `
                            : "..."}
                          <span className="offer-profiledetails-divider">
                            <br/>
                          </span>
                        </span>

                        <span title="Trade must be completed within 30 mins">
                          <i className="fa fa-stopwatch profiledetails-icon"></i>{" "}
                          30mins
                        </span>
                      </>
                    </div>
                  </div>
                </div>
              </td>
              <td
                data-label="Price:"
                title="Rate per USD"
                className="offers-table-cols"
              >
                <div className="offer_price">
                  <span className="offerprice">
                    <span>
                      {new Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(parseFloat(hit.tradeRate))}
                      <sub>
                        {hit.cryptoCurrency.code == "USDT"
                          ? ""
                          : `1 ${hit.cryptoCurrency.code} =`}{" "}
                        {hit.fiatCurrency.code}{" "}
                      </sub>
                    </span>{" "}
                  </span>{" "}
                  <br />
                </div>
              </td>
              <td data-label="AvailableAmount:" className="offers-table-cols">
                <div className="offer_price ">
                  <span
                    title="Available amount to trade with"
                    className="font-weight-bold font-1_2 offer_price-usd"
                  >
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(parseFloat(hit.availableAmount || "0"))}
                    <sub>USD </sub>
                  </span>
                  <br />
                  {hit.rangeMin > 0 && hit.rangeMin > 0 && (
                    <div title="Minimun and Maximum amount allowed in this trade">
                      <span className="offerslist-minmax">
                        <span>Min:&nbsp;</span>
                        {new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(hit.rangeMin)}{" "}
                        {hit.fiatCurrency.code}
                        {" "}
                        (
                          {calculateMinUSD()}
                        )
                      </span>
                      <br />
                      <span className="offerslist-minmax">
                        <span>Max:&nbsp;</span>
                        {new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(hit.rangeMax)}{" "}
                        {hit.fiatCurrency.code}
                        {" "}
                        (
                          {calculateMaxUSD()}
                        )
                      </span>
                    </div>
                  )}
                </div>
              </td>
              <td data-label="Payment Method:" className="offers-table-cols">
                <div className="offer_paymentmethod">
                  <p className="avatar-offer"></p>
                  <p className="offer_paymentmethod-name">
                    {hit.paymentMethod.name}
                  </p>
                </div>
              </td>
              <td className="offers-table-cols">
                <div className="offer_actions d-flex pl-2">
                  {currentExternalProfileId !== hit.profile?.id && (
                    <div className="hit-action">
                      <a
                        href={`/p2p_trades/offers/trade/${hit.id}/create`}
                        className={`btn offers-actionbtns ${
                          hit.offerTypeField === "buy" ? "sell-btn" : "buy-btn"
                        } float-right`}
                      >
                        {hit.offerTypeField === "buy" ? "Sell" : "Buy"}{" "}
                        {hit.cryptoCurrency.code === "USDT"
                          ? "USD"
                          : hit.cryptoCurrency.code}
                      </a>
                    </div>
                  )}
                  {currentExternalProfileId === hit.profile?.id && (
                    <div className="my-offer-action">
                      <label className="myoffer-status-cont">
                        <span>{hit.active ? "Active" : "Inactive"}</span>
                        <br />
                        <Switch
                          onChange={(e) => changeOfferStatus(hit, e)}
                          className="react-switch"
                          checked={hit.active}
                          disabled={!hit.canDeactivate}
                        />
                      </label>
                      <a
                        href={`/p2p_trades/offers/${hit.id}/edit`}
                        className="myoffers-edit"
                      >
                        <span>
                          <i className="fa fa-pencil"></i> Edit
                        </span>
                      </a>{" "}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HitComponent;
