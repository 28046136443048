import React, { useState } from "react";
import {
  Formik,
  Form,
  Field,
  FormikHelpers,
  FieldArray,
  ErrorMessage
} from "formik";
import Select from "react-select";
import { required } from "./validations";
import { postRequest, putRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";
import { url, validateName, multipleUrls } from "./validations";

const CryptoRewardsForm = ({
  store_id,
  integration,
  allTokens,
  selectedTokens,
  showSuccessModal
}) => {
  const [creating, setCreating] = useState(false);
  const [errors, setErrors] = useState({});

  const submit = values => {
    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    const data = {
      store_integration: {
        oauth2_name: values.oauth2_name,
        notifications_url: values.notifications_url,
        redirect_url: values.redirect_url,
        token_ids: values.tokens.map(token => token.id),
        integration_type: "CryptoRewards"
      }
    };
    setCreating(true);

    if (integration) {
      putRequest(`/stores/${store_id}/integrations/${integration.id}`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          showSuccessModal();
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    } else {
      postRequest(`/stores/${store_id}/integrations`, data, {
        "X-CSRF-Token": crsfToken
      })
        .then(response => {
          window.location = `/stores/${store_id}/integrations`;
        })
        .catch(error => {
          const errors = formErrors(error.data.errors);

          setErrors(error);
          setCreating(false);
        });
    }
  };

  return (
    <Formik
      initialValues={{
        notifications_url: integration?.notifications_url || "",
        tokens: selectedTokens || [],
        oauth2_name: integration?.oauth2_name || "",
        redirect_url: integration?.redirect_url || ""
      }}
      onSubmit={values => submit(values)}
    >
      {({ values, setFieldValue, touched, handleChange }) => (
        <Form className="form">
          <div className="text-center pt-2 pb-2">
            <hr />
            <h4 className="pt-2 pb-2 store-default-title">Reward Tokens</h4>
            <hr />
          </div>
          <div className="form-group required row">
            <label htmlFor="tokens" className="form-label store-new-formlabel">
              Tokens
            </label>
            <Select
              name="tokens"
              options={allTokens}
              isMulti
              className="tokens-select"
              value={values.tokens}
              required={required}
              onChange={tokens => setFieldValue("tokens", tokens)}
            />
          </div>
          <div className="text-center pt-2 pb-2">
            <hr />
            <h4 className="pt-2 pb-2 store-default-title">Settings</h4>
            <hr />
          </div>
          <div className="form-group required row">
            <label htmlFor="notifications_url" className="form-label store-new-formlabel">
              Name of your Store or Integration
            </label>
            <Field
              id="oauth2_name"
              name="oauth2_name"
              className="form-control"
              onChange={handleChange}
              validate={validateName}
              placeholder=""
            />
            <ErrorMessage name="oauth2_name">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="text-muted row font-0_75">
            This name will be displayed to your customers when they are login in to their BananaCrystal account from your store/platform (OAuth2.0 App Name).
            <br />
            Example: MyStoreName
          </div>

          <div className="form-group required row">
            <label htmlFor="redirect_url" className="form-label store-new-formlabel">
              Redirect Urls
            </label>
            <Field
              id="redirect_url"
              name="redirect_url"
              className="form-control"
              onChange={handleChange}
              validate={multipleUrls}
            />
            <ErrorMessage name="redirect_url">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="text-muted row font-0_75">
            The url(s) where your customer will be redirected after logging in with their BananaCrystal account.
            <br/> Separate several urls by a single space (OAuth2.0 Redirect URI).
            <br />
            Example: https://mystore.com/ https://mystore.com/my-account
          </div>
          
          <div className="form-group row pt-4">
            <label htmlFor="notifications_url" className="form-label store-new-formlabel">
              Withdrawal notifications URL
            </label>
            <Field
              id="notifications_url"
              name="notifications_url"
              className="form-control"
              onChange={handleChange}
              placeholder=""
            />
            <ErrorMessage name="notifications_url">
              {msg => <div className="error">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="text-muted row font-0_75">
            The url where your store or platform will receive noticiations when your customers redeem their tokens (Webhook URL).
            <br />
            <br />
            Example: https://mystore.com/my-notifications-url/
          </div>
          
          <div className="form actions">
            <div className="row mx-auto">
              <div className="col-md-12 text-right pr-0 pt-4">
                <button
                  type="submit"
                  className="btn btn-primary store-paidbtn"
                  disabled={creating}
                >
                  {creating && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CryptoRewardsForm;
