import React, { useEffect, useState } from "react";
import { getRequest, patchRequest } from "../../utils/httpRequest";

const DisputeNotificationBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [disputes, setDisputes] = useState([]);
  const [fetchUrl, setFetchUrl] = useState("/api/v1/user_disputes/first_not_notified_dispute");
  const [updateUrl, setUpdateUrl] = useState("");

  const fetchDisputes = async () => {
    try {
      const data = await getRequest(fetchUrl);
      if (data.id) {
        setDisputes(data);
        setIsVisible(true);
        // Set the correct column for updating notified status
        const updateColumn = data.is_claimant ? "claimant_notified" : "respondent_notified";
        setUpdateUrl(`/api/v1/user_disputes/${data.id}/mark_as_notified?notified=${updateColumn}`);
      }
    } catch (error) {
      // console.error("Error fetching disputes:", error);
    }
  };

  const updateDisputeNotified = async () => {
    try {
      const response = await patchRequest(updateUrl, {
        value: "yes",
        bypass_trade_expiry_validation: true, 
      });

      if (response.id) {
        setIsVisible(false); // Hide the banner after successful update
      } else {
        console.warn("Failed to update dispute notification");
      }
    } catch (error) {
      console.error("Error updating dispute notification:", error);
    }
  };


  const handleDisputeUpdateAndRedirect = async () => {
    await updateDisputeNotified(); // Wait for the function to complete
    window.location = `/p2p_trades/trades/${disputes.p2p_trade_id}`; // Now change the location after the function finishes
  };

  useEffect(() => {
    fetchDisputes();
  }, []);

  if (!isVisible) return null;
  
  return (
    <div className="dispute-banner-bg">
      <p className="dispute-banner-text">
      <i className="fa fa-bell bell-icon"></i>
      {" "}
        A dispute has been raised for this{" "}
        <a
          onClick={handleDisputeUpdateAndRedirect}
          className="dispute-trade-url"
        >
          <u>trade</u>
        </a>{" "}
      </p>
      <button className="dispute-close-button" onClick={updateDisputeNotified}>
        ×
      </button>
    </div>
  );
};

export default DisputeNotificationBanner;
