import React from "react";

const Products = ({ data }) => {
  const p2p = {
    title: "P2P",
    items: [
      { label: "P2P Trades Count", value: data.p2p_trades_count },
      { label: "P2P Trades Volume", value: data.p2p_trades_volume },
    ],
  };

  const virtual_terminal_pos = {
    title: "Virtual Terminal POS",
    items: [
      { label: "Total Store Count", value: data.total_store_count },
      { label: "Store Owner Count", value: data.store_owner_count },
      {
        label: "Store Transaction Count",
        value: data.store_transaction_count,
      },
      {
        label: "Store Transactions Volume",
        value: data.store_transactions_volume,
      },
    ],
  };

  const usd_debit_card = {
    title: "USD Debit Card",
    items: [
      {
        label: "Active Card Holders Count",
        value: data.active_card_holders_count,
      },
      {
        label: "Card Application Count",
        value: data.applied_card_user_count,
      },
    ],
  };

  return (
    <div
      style={{
        border: "1px solid gray",
        borderRadius: "8px",
        backgroundColor: "rgb(47, 61, 98)",
        padding: "16px",
        marginBottom: "16px",
        borderColor: "rgb(76, 63, 132)",
      }}
    >
      <h1 style={{ marginBottom: "8px", color: "#fff" }}>Products</h1>
      <div
        style={{
          height: "4px",
          backgroundColor: "rgb(238, 235, 250)",
          marginBottom: "16px",
          borderRadius: "2px",
        }}
      ></div>
      <ul
        style={{
          listStyleType: "none",
          width: "94%",
          padding: "15px",
          border: "1px solid #f0f0f5",
          borderRadius: "5px",
          background:
            "radial-gradient(circle,rgba(160, 180, 210, 0.21) 0%,rgba(172, 181, 195, 0.36) 0%)",
        }}
      >
        <h3 style={{ color: "#fff" }}>{p2p.title}</h3>
        {p2p.items.map((item, index) => (
          <li
            key={index}
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "4px",
            }}
          >
            <span style={{ color: "#fff" }}>{item.label}</span>
            <strong style={{ color: "#fff" }}>
              {item.label === "P2P Trades Volume"
                ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(Number(item.value).toFixed(2))
                : item.value || "0"}
            </strong>
          </li>
        ))}
      </ul>

      <ul
        style={{
          listStyleType: "none",
          width: "94%",
          padding: "15px",
          border: "1px solid rgb(255, 218, 183)",
          borderRadius: "5px",
          background:
            "radial-gradient(circle,rgba(228, 253, 255, 0.19) 0%,rgba(231, 241, 255, 0.2) 100%)",
        }}
      >
        <h3 style={{ color: "#fff" }}>{virtual_terminal_pos.title}</h3>
        {virtual_terminal_pos.items.map((item, index) => (
          <li
            key={index}
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "4px",
            }}
          >
            <span style={{ color: "rgb(238, 235, 250)" }}>{item.label}</span>
            <strong style={{ color: "rgb(238, 235, 250)" }}>
              {item.label === "Store Transactions Volume"
                ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(Number(item.value).toFixed(2))
                : item.value || "0"}
            </strong>
          </li>
        ))}
      </ul>

      <ul
        style={{
          listStyleType: "none",
          width: "94%",
          padding: "15px",
          border: "1px solid #f0f0f5",
          borderRadius: "5px",
          background:
            "linear-gradient(90deg,rgba(255, 236, 210, 0.23) 45%,rgba(255, 233, 225, 0.27) 100%)",
        }}
      >
        <h3 style={{ color: "rgb(238, 235, 250)" }}>{usd_debit_card.title}</h3>
        {usd_debit_card.items.map((item, index) => (
          <li
            key={index}
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: "4px",
            }}
          >
            <span style={{ color: "rgb(238, 235, 250)" }}>{item.label}</span>
            <strong style={{ color: "rgb(238, 235, 250)" }}>
              {item.value || "0"}
            </strong>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Products;
