import React from "react";

const SingleTotals = ({
  discardedUsers,
  unsubscribedEmails,
  userGrowthData,
  referrals,
}) => {
  console.log(userGrowthData)
  const discardedUsersCount = discardedUsers?.data?.discarded_users_count || 0;
  const unsubscribedEmailsCount =
    unsubscribedEmails?.data?.unsubscribed_emails || 0;
  const totalUsers =
    Number(userGrowthData.data?.verified_users.total_users || "0") +
    Number(userGrowthData.data?.unverified_users.total_users || "0");
  const emailData = referrals.data ? Object.entries(referrals?.data).map(([email, value]) => ({
    email,
    value,
  })) : [];

  return (
    <div
      style={{
        border: "1px solid gray",
        borderRadius: "8px",
        backgroundColor: "rgb(246, 224, 255)",
        borderColor: "rgb(47, 61, 98)",
        padding: "16px",
        marginBottom: "16px",
        minWidth: "90%",
        backgroundImage:
          "url('http://www.bananacrystal.com/wp-content/uploads/2024/12/store-balance-bg.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        zIndex: "0",
      }}
    >
      <h1 style={{ marginBottom: "8px", color: "#2e3d62" }}>
        <strong>Overview</strong>
      </h1>

      <div>
        {/* Single Data Display */}
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignItems: "center",
              paddingBottom: "4px",
            }}
          >
            <h4 style={{ marginBottom: "8px" }}>
              <strong>Total Users (Verified + Unverified)</strong>
            </h4>

            <span style={{ color: "#555" }}>{totalUsers}</span>
          </li>
          <div
            style={{
              height: "4px",
              backgroundColor: "rgb(76, 63, 132)",
              marginBottom: "16px",
              borderRadius: "2px",
            }}
          ></div>
        </ul>
      </div>

      <div>
        {/* Single Data Display */}
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignItems: "center",
              paddingBottom: "4px",
            }}
          >
            <h4 style={{ marginBottom: "8px" }}>
              <strong>Total Discarded Users</strong>{" "}
            </h4>

            <span style={{ color: "#555" }}>{discardedUsersCount}</span>
          </li>
          <div
            style={{
              height: "4px",
              backgroundColor: "rgb(76, 63, 132)",
              marginBottom: "16px",
              borderRadius: "2px",
            }}
          ></div>
        </ul>
      </div>

      <div>
        {/* Single Data Display */}
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignItems: "center",
              paddingBottom: "4px",
            }}
          >
            <h4 style={{ marginBottom: "8px" }}>
              <strong>Total Unsubscribed Emails</strong>
            </h4>

            <span style={{ color: "#555" }}>{unsubscribedEmailsCount}</span>
          </li>
          <div
            style={{
              height: "4px",
              backgroundColor: "rgb(76, 63, 132)",
              marginBottom: "16px",
              borderRadius: "2px",
            }}
          ></div>
        </ul>
      </div>

      <div>
        {/* Single Data Display */}
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li
            style={{
              marginBottom: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignItems: "center",
              paddingBottom: "4px",
            }}
          >
            <h4 style={{ marginBottom: "8px" }}>
              <strong>Total Referrers</strong>
            </h4>

            <span style={{ color: "#555" }}>{emailData.length}</span>
          </li>
          <div
            style={{
              height: "4px",
              backgroundColor: "rgb(76, 63, 132)",
              marginBottom: "16px",
              borderRadius: "2px",
            }}
          ></div>
        </ul>
      </div>
    </div>
  );
};

export default SingleTotals;
