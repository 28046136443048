import React, { useEffect } from "react";

const Body = ({ transfers, removeTransfer }) => {
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const getAmount = (transfer) => {
    if (transfer.is_convert) {
      return (
        <>
          {transfer.gross_amount} {transfer.source_currency}{" "}
          <i className="fa fa-arrow-right" /> {transfer.net_amount}{" "}
          {transfer.destination_currency == "USDT"
            ? "USD"
            : transfer.destination_currency}
        </>
      );
    }

    return `${transfer.net_amount} ${
      transfer.destination_currency === "USDT"
        ? "USD"
        : transfer.destination_currency
    }`;
  };

  const getDescription = (transfer) => {
    const {
      source_currency,
      destination_currency,
      source_wallet_id,
      destination_wallet_id,
      default_crypto_wallet_id,
      is_destination_user_wallet,
      is_destination_store_wallet,
      destination_wallet_user_name,
      destination_wallet_user_email,
      source_wallet_user_name,
      source_wallet_user_email,
      destination_wallet_store_name,
      destination_wallet_store_address,
      destination_wallet_store_email,
      blockchain_tx,
      blockchain_address,
      user_email,
      user_name,
    } = transfer;

    if (source_currency !== destination_currency) return <></>;

    if (is_destination_store_wallet) {
      return (
        <>
          {destination_wallet_store_name}
          <br /> {destination_wallet_store_address}
          <br /> {destination_wallet_store_email}
        </>
      );
    } else if (
      destination_wallet_id === default_crypto_wallet_id
    ) {
      return destination_wallet_id && (
        <>
          {source_wallet_user_name} <br />
          {source_wallet_user_email}
        </>
      )
    }
    else if (
      !source_wallet_id ||
      destination_wallet_id === default_crypto_wallet_id
    ) {
      return source_wallet_id ? (
        <>
          {user_name} <br />
          {user_email}
        </>
      ) : (
        <>
          External Wallet
          <br />({source_currency}){blockchain_tx}
        </>
      );
    } 
    else if (is_destination_user_wallet) {
      return (
        <>
          {destination_wallet_user_name}
          <br /> {destination_wallet_user_email}
        </>
      );
    } else {
      return (
        <>
          External Wallet
          <br /> ({source_currency} {blockchain_address})
        </>
      );
    }
  };

  const getDescriptionMeta = (transfer) => {
    if (transfer.completed_at) {
      return (
        <div className="font-0_85">Completed: {transfer.completed_at}</div>
      );
    }

    return <div className="font-0_85">Created: {transfer.created_at}</div>;
  };

  const getExpiredMeta = (transfer) => {
    if (transfer.expires_at && transfer.is_expired) {
      return <div className="font-0_85">Expired: {transfer.expires_at}</div>;
    }

    return <></>;
  };

  const getIncompleteTransferStatus = (transfer) => {
    const { is_complete, is_confirmed, is_unconfirmed, is_failed, is_expired } =
      transfer;

    if (
      !is_complete &&
      (!is_confirmed || is_unconfirmed) &&
      !is_failed &&
      !is_expired
    ) {
      return (
        <a
          href="#"
          data-toggle="tooltip"
          className={transfer.active_class_by_status}
          title="This transaction is pending as it needs your email confirmation.
              Please click on the confirmation link sent to your email"
        >
          <span>
            <i className="fa fa-info-circle"></i>
          </span>
        </a>
      );
    }

    return <></>;
  };

  const getStatus = (transfer) => {
    if (transfer.is_expired) {
      return (
        <a href={`/crypto_transfers/${transfer.id}`} className="unlink">
          Expired
        </a>
      );
    } else {
      return (
        <>
          {transfer.external_status} {getIncompleteTransferStatus(transfer)}
        </>
      );
    }

    return <></>;
  };

  const getResendConfirmationLink = (transfer) => {
    const {
      is_complete,
      is_confirmed,
      is_unconfirmed,
      is_failed,
      is_expired,
      is_pending,
      id,
    } = transfer;

    if (
      !is_complete &&
      (!is_confirmed || is_unconfirmed) &&
      !is_failed &&
      !is_expired &&
      !is_pending
    ) {
      return (
        <a
          className={`btn btn-link ${transfer.active_class_by_status}`}
          title="Resend Confirmation"
          data-confirm="A confirmation link will be sent to your email"
          data-remote="true"
          href={`/crypto_transfers/${id}/resend_confirmation`}
        >
          <svg
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            height={20}
            width={20}
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M44 24V9H24H4V24V39H24"
                stroke="#000000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M44 34L30 34"
                stroke="#000000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M39 29L44 34L39 39"
                stroke="#000000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
              <path
                d="M4 9L24 24L44 9"
                stroke="#000000"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
        </a>
      );
    }

    return <></>;
  };

  const getRemoveTransactionLink = (transfer) => {
    const { is_kept, is_complete, is_pending, id } = transfer;

    if (is_kept && !is_complete && !is_pending) {
      return (
        <a
          className="unlink"
          title="Remove transaction"
          rel="nofollow"
          onClick={(e) => removeTransfer(e, transfer.id)}
        >
          <svg
            viewBox="0 0 24 24"
            height={20}
            width={20}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17"
                stroke="#bd4242"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>{" "}
            </g>
          </svg>
        </a>
      );
    }

    return <></>;
  };

  const handleRowClick = (e, transferId) => {
    if (!e.target.closest("td:last-child")) {
      window.location.href = `/crypto_transfers/${transferId}`;
    }
  };

  return (
    <tbody className="table-body">
      {transfers.map((transfer, i) => (
        <tr
          key={transfer.id}
          className="cursor-pointer"
          onClick={(e) => handleRowClick(e, transfer.id)}
        >
          <td className="pl-2 ml-1">
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Date:</span>
              <span>{transfer.date_created}</span>
            </div>
          </td>
          <td>
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Amount:</span>
              <span>{getAmount(transfer)}</span>
            </div>
          </td>
          <td>
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Type:</span>
              <span className="activity-type-text">{transfer.transfer_type}</span>
            </div>
          </td>
          <td className="expandable-description">
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Description:</span>
              <span>
                {getDescription(transfer)}
                <div className="collapse" id={`collapseDescription${i}`}>
                  {getDescriptionMeta(transfer)}
                  {getExpiredMeta(transfer)}
                </div>
              </span>
            </div>
          </td>
          <td className={transfer.active_class_by_status}>
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Status:</span>
              <span>{getStatus(transfer)}</span>
            </div>
          </td>
          <td className="actions trash">
            <span>
              <a className="btn-link" href={`/crypto_transfers/${transfer.id}`}>
                View <i className="fa fa-arrow-right"></i>
              </a>
            </span>
            {/* <span className={transfer.active_class_by_status}>
              {getResendConfirmationLink(transfer)}
            </span>
            <span>{getRemoveTransactionLink(transfer)}</span> */}
          </td>
        </tr>
      ))}
      {transfers.length === 0 && (
        <tr>
          <td colSpan={8} className="text-center">
            Currently you have no activity or clear your filters.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default Body;
