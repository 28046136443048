import React from "react";

const Preview = ({
  fees,
  amount,
  netAmount,
  selectedCurrency,
  firstTimeWithdrawalFee,
  isFirstTimeWithdrawal
}) => (
  <div className="preview-wrapper row">
    <h4>Review Your Transfer Details</h4>
    <hr />
    <div className="bg-gray rounded-lg d-flex flex-row justify-content-around px-md-3 pb-3 mobileonly-preview">
      <div className="text-center store-sendfunds-ovalpixels-previewsects">
        <div className="font-weight-bold">      
          <label className="form-label">
            Amount
            <a
              data-toggle="tooltip"
              className="btn btn-link"
              data-original-title="Subtotal amount (Crypto)."
            >
              {" "}
              <i className="fa fa-info-circle"></i>
            </a>
          </label>
          <small
            id="netAmountHelpBlock"
            className="form-text text-muted"
          ></small>
          {" "}
        </div>
        <div className="text-center store-sendfunds-previewsects">
          <div className="input-group-prepend">
            <span className="input-group-text selected-currency ml-0 border-0">
              {selectedCurrency}
              {" "}
              <input
                className="form-control border-0 pr-0"
                placeholder="0.00"
                data-target="cryptoTransfer.outputNetAmount"
                required="required"
                readOnly="readonly"
                type="text"
                value={parseFloat(amount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              />
            </span>
          </div>     
        </div>
      </div>
      <div className="text-center store-sendfunds-ovalpixels-previewsects">
        <div className="font-weight-bold"> 
            <label className="form-label">
              Fee
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Transaction and processing fees (Crypto)"
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small id="feesHelpBlock" className="form-text text-muted"></small>
          {" "}
        </div>
        <div className="text-center store-sendfunds-previewsects">
          <div className="input-group-prepend">
            <span className="input-group-text selected-currency border-0">
                {selectedCurrency}
              <input
                className="form-control border-0"
                placeholder="TBD"
                data-target="cryptoTransfer.outputFeesAmount"
                required="required"
                readOnly="readonly"
                type="text"
                value={parseFloat(fees).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              />
            </span>
            {" "}
          </div>
        </div>
      </div>
      {isFirstTimeWithdrawal && (
        <div className="text-center store-sendfunds-ovalpixels-previewsects">
          <div className="font-weight-bold"> 
              <label className="form-label">
                One Time Setup Fee
                <a
                  data-toggle="tooltip"
                  className="btn btn-link pl-1 pr-0"
                  data-original-title="Transaction and processing fees (Crypto)"
                >
                  <i className="fa fa-info-circle"></i>
                </a>
              </label>
              <small
                id="feesHelpBlock"
                className="form-text text-muted"
              ></small>
            {" "}
          </div>
          <div className="text-center store-sendfunds-previewsects">
            <div className="input-group-prepend">
              <span className="input-group-text selected-currency border-0">
                {selectedCurrency}
                <input
                  className="form-control border-0"
                  placeholder="TBD"
                  data-target="cryptoTransfer.outputFeesAmount"
                  required="required"
                  readOnly="readonly"
                  type="text"
                  value={parseFloat(firstTimeWithdrawalFee).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
              </span>
            </div>{" "}
          </div>
        </div>
      )}
        <div className="text-center store-sendfunds-ovalpixels-previewsects">
          <div className="font-weight-bold"> 
              <label className="form-label">
              Total
              <a
                data-toggle="tooltip"
                className="btn btn-link"
                data-original-title="Transaction and processing fees (Crypto)"
              >
                <i className="fa fa-info-circle"></i>
              </a>
            </label>
            <small id="feesHelpBlock" className="form-text text-muted"></small>
          {" "}
        </div>
        <div className="text-center store-sendfunds-previewsects">
            <div className="input-group-prepend">
              <span className="input-group-text selected-currency border-0">
                  {selectedCurrency}
              <input
                className="form-control border-0"
                placeholder="TBD"
                data-target="cryptoTransfer.outputFeesAmount"
                required="required"
                readOnly="readonly"
                type="text"
                value={parseFloat(netAmount).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              />
            </span>
          </div>{" "}
        </div>
      </div>
    </div>
  </div>
);

export default Preview;
