import React, { useState, useEffect } from 'react';
import { putRequest } from "../../utils/httpRequest";

const DeleteActivityModal = ({ proceedWithDeletion, closeModal, modalButtonText }) => {

  return (
    <>
    <div id="removeActivity-modal">
      <div
        className="modal-delistoffers-alert-cont"
        aria-hidden="true"
        aria-labelledby="delistOffers"
      >
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <br/><br/>
        <div>
          <h5 className="delistoffer-modal-title">
          <i className="fas fa-circle-exclamation"></i>
            <span>Are you sure?</span>
          </h5>
          <p className='text-center'>Note: This action cannot be undone.</p>
          <div className="delistoffer-modal-buttons-section">
            <button
              type="button"
              id="deleteActivityProceedBtn"
              className="delistoffer-modal-tradeaction-proceedbtn"
              onClick={(e) => {
                proceedWithDeletion(e)
              }}
            >
              {modalButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default DeleteActivityModal;
