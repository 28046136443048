import React from "react";
import SelectCurrency from "./SelectCurrency";
import BlockchainTransfer from "./BlockchainTransfer";
import WalletTransfer from "./WalletTransfer";
import Preview from "./Preview";
import Actions from "./Actions";

import opaig from "../../../assets/images/crypto/opaig.png";

const CryptoForm = ({
  stateParams,
  propsParams,
  handleBlockchainAddressChange,
  handleAmountChange,
  showModal,
  handleChange,
  cancelTransfer,
  refreshPage,
  submitTransferPreview,
  submitTransfer,
  handleRecipientChange,
  handleSelectedCurrencyChange,
}) => (
  <form className="form-horizontal transfer-form" id="new_crypto_transfer">
    <div className="d-flex flex-column">
      <div className="withdraw-step">
        <div className="withdraw-count">1</div>
        <h5 className="font-weight-bold font-0_95">Select Coin</h5>

        <div className="d-flex flex-column">
          <p className="row-title font-1_0 pt-2">
            Select from available crypto assets :
          </p>
        </div>
        <div className="input-group mb-2 coin-input-wrapper">
          <div className="input-group-prepend">
            <div className="input-group-text p-1">
              <div className="d-flex align-items-center coin-image-input">
                <img
                  src={
                    stateParams.selectedCurrency.toLowerCase() === "opaig"
                      ? opaig
                      : `https://media.bananacrystal.com/images/crypto/${stateParams.selectedCurrency.toLowerCase()}.png`
                  }
                  height={24}
                  width={24}
                />
                <span className="ml-1 font-weight-bold">
                  {stateParams.selectedCurrency}
                </span>
              </div>
            </div>
          </div>
          <SelectCurrency
            type={propsParams.type}
            selectedCurrency={stateParams.selectedCurrency}
            currencies={propsParams.currencies}
            handleChange={handleChange}
            handleSelectedCurrencyChange={handleSelectedCurrencyChange}
          />
          <div className="drop-icon-wrapper">
            <i className="fa fa-chevron-down"></i>
          </div>
        </div>

        <div className="card-network mt-4">
          <p className="row-title font-1_0">Withdrawal Network</p>
          <ul>
            <li className="active">POL</li>
            <li>
              <img
                className="mr-2"
                src={
                  "https://media.bananacrystal.com/wp-content/uploads/2023/07/20151846/matic.png"
                }
                width={24}
                height={24}
              />
              POLYGON NETWORK
            </li>
          </ul>
          <p className="font-0_8 text-warning">
            <i className="fa fa-clock"></i> Average Arrival Time:{" "}
            <strong>3 Minutes</strong>
          </p>
        </div>
      </div>

      <div className="withdraw-step">
        <div className="withdraw-count">2</div>
        <h5 className="font-weight-bold font-0_95">Withdraw To</h5>
        <div className="withdraw-input-wrapper pt-3">
          <div className="input-group mb-2 address-input-wrapper">
            {propsParams.type === "blockchain" && (
              <BlockchainTransfer
                blockchainAddress={stateParams.blockchainAddress}
                validationErrors={stateParams.validationErrors}
                selectedCurrency={stateParams.selectedCurrency}
                handleBlockchainAddressChange={handleBlockchainAddressChange}
                showModal={showModal}
              />
            )}
            {propsParams.type === "wallet" && (
              <WalletTransfer
                selectedTransferType={stateParams.selectedTransferType}
                submitting={stateParams.submitting}
                transferTypes={stateParams.transferTypes}
                destinationWallets={stateParams.destinationWallets}
                validationErrors={stateParams.validationErrors}
                recipient={stateParams.recipient}
                recipients={stateParams.recipients}
                lookedUpRecipient={stateParams.lookedUpRecipient}
                selectedDestinationWallet={
                  stateParams.selectedDestinationWallet
                }
                handleChange={handleChange}
                handleRecipientChange={handleRecipientChange}
              />
            )}
          </div>
        </div>
      </div>

      <div className="withdraw-step">
        <div className="withdraw-count">3</div>
        <h5 className="font-weight-bold font-0_95">Withdraw Amount</h5>
        <div className="withdraw-input-wrapper amount-input-wrapper">
          <label className="form-label">
            <p>
              Enter total transfer amount desired (
              {stateParams.selectedCurrency}
              ).
            </p>
          </label>
          <div className="w-100 text-right">
            <small>
              Available balance:{" "}
              <strong>
                {Number(stateParams.selectedCurrencyBalance).toLocaleString(
                  undefined,
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}
              </strong>
            </small>
          </div>
          <div
            className={`input-group address-input-wrapper ${
              stateParams.validationErrors?.amountError ? "is-invalid" : ""
            }`}
          >
            <div className="input-group-prepend">
              <div className="input-group-text selected-currency">
                {stateParams.selectedCurrency}
              </div>
            </div>
            <input
              step="any"
              style={{ maxWidth: "800px" }}
              placeholder="0.000"
              className={`form-control `}
              required="required"
              type="number"
              value={stateParams.amount}
              onChange={(e) => handleAmountChange(e)}
              id="amount"
              disabled={stateParams.submitting == true ? true : false}
            />
            <div
              className="max-btn"
              onClick={() =>
                handleAmountChange({
                  target: {
                    value: stateParams.selectedCurrencyBalance.toFixed(2),
                  },
                })
              }
            >
              MAX
            </div>
          </div>
          {stateParams.error && (
            <small className="error text-danger">{stateParams.error}</small>
          )}
          {stateParams.validationErrors?.amountError && (
            <small className="error text-danger">
              {stateParams.validationErrors.amountError}
            </small>
          )}
          <div>
            <div className="mt-3 mb-3 position-relative amount-slider-cont">
              <input
                type="range"
                min="0"
                max={stateParams.selectedCurrencyBalance > 0 ? stateParams.selectedCurrencyBalance : "0"}
                step="0.01"
                value={
                  stateParams.amount === ""
                    ? "0"
                    : String(stateParams.amount).endsWith(".")
                    ? parseFloat(stateParams.amount) > 0
                      ? String(stateParams.amount).slice(0, -1)
                      : "0"
                    : stateParams.amount
                }                
                className="form-range styled-range w-100"
                onChange={(e) =>
                  handleAmountChange({ target: { value: parseFloat(e.target.value) } })
                }
                aria-label="Transfer Amount Slider"
                disabled={
                  stateParams.submitting
                }
              />
              <div className="slider-labels d-flex justify-content-between mt-1">
                <span>0%</span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i>
                </span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i>
                </span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                <span className="slider-markers"><i class="fa fa-circle" style={{color: "#f1f3f5"}}></i></span>
                <span>
                  Max:{" "}
                  {stateParams.selectedCurrencyBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  {stateParams.selectedCurrency}
                </span>
              </div>
            </div>
          </div>
          <br/>
        </div>
      </div>

      <div className="withdraw-step">
        <div className="withdraw-count">4</div>
        <h5 className="font-weight-bold font-0_95">
          Review Your Transfer Details
        </h5>
        <Preview
          amount={stateParams.amount}
          fees={stateParams.fees}
          netAmount={stateParams.netAmount}
          selectedCurrency={stateParams.selectedCurrency}
          firstTimeWithdrawalFee={stateParams.firstTimeWithdrawalFee}
          isFirstTimeWithdrawal={propsParams.isFirstTimeWithdrawal}
        />
      </div>
    </div>
    <div className="amount-wrapper">
      <div className="source-wallet-wrapper">
        <select
          className="border-0 mx-0 px-0"
          style={{
            color: "transparent",
            WebkitAppearance: "none",
            MozAppearance: "none",
          }}
          value={stateParams.electedSourceWallet}
          onChange={(e) => handleChange(e, "selectedSourceWallet")}
        >
          {propsParams.sourceWallets.map((wallet, i) => (
            <option value={wallet[1]} key={i}></option>
          ))}
        </select>
      </div>

      <div className="text-muted font-0_9 mt-2">
        {stateParams.submitting ? (
          <div className="pt-4 mt-2">
            <i className="fa fa-exclamation-circle"></i> The quoted transfer
            fees are <strong>valid for the next 10 min</strong>. If your quote
            expires, simply start again. Click Submit to continue.
          </div>
        ) : (
          <div>
            <i className="fa fa-exclamation-circle"></i> Click Preview Transfer
            to preview your transfer including the applicable network fees.
          </div>
        )}
      </div>
      <hr />
      <Actions
        canSubmitTransfer={stateParams.canSubmitTransfer}
        submitting={stateParams.submitting}
        destinationAmount={stateParams.destinationAmount}
        transferId={stateParams.transferId}
        invalidTransfer={stateParams.invalidTransfer}
        cancelTransfer={cancelTransfer}
        refreshPage={refreshPage}
        submitTransfer={submitTransfer}
        submitTransferPreview={submitTransferPreview}
      />
    </div>
  </form>
);

export default CryptoForm;
