import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Option } from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import PhoneCodes from "../../../utils/countryPhoneCodes.json"
import 'react-phone-number-input/style.css';
import "../phone_styles.scss"
import "./modal.scss"
import SelectField from "../../Cards/Requests/New/common/SelectField";
import { validationSchema, countryOptions, findCountryID } from "./helper";
import { required } from "../../../utils/formValidations";
import { putRequest, postRequest } from "../../../utils/httpRequest";

interface AddressFormModalProps {
  isOpen: boolean;
  closeModal: () => void;
  verificationId: string;
  setCanStartVerification: (canStartVerification: boolean) => void;
  kycDetail: {
    country: string;
    state: string;
    address: string;
    zip_code: string;
    phone: string;
    phone_code: string;
    national_id_number: string;
    city: string;
  }
}

const AddressFormModal: React.FC<AddressFormModalProps> = ({
  isOpen,
  closeModal,
  verificationId,
  setCanStartVerification,
  kycDetail
}) => {
  const [selectedCountry, setSelectedCountry] = useState(
    {
      country: "Nigeria",
      idName: "National Identity Number"
    })

  const onSubmit = (values, setSubmitting) => {
    setSubmitting(true);

    const phoneNumber = parsePhoneNumber(values.phone)

    const data = {
      kyc_user_detail: {
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country,
        zip_code: values.zipCode,
        national_id_number: values.nationalIDNumber,
        phone_code: phoneNumber.countryCallingCode,
        phone: phoneNumber.nationalNumber
      },
    };

    postRequest(`/api/v1/kyc_user_details`, data)
      .then((response) => {
        setSubmitting(false);
        setCanStartVerification(true);
        closeModal();
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
      });
  };

  const handleIDType = (country) => {
    const getCountry = findCountryID(country)
    getCountry !== undefined ? setSelectedCountry({ ...getCountry }) : setSelectedCountry({
      country: "country",
      idName: "National Identity Number"
    })
  }

  useEffect(() => {
    const country = countryOptions.find(({ value }) => value === kycDetail?.country);
    
    if (country) {
      handleIDType(country.label);
    }
  }, [kycDetail, countryOptions]);
  

  const allowedCountries =  PhoneCodes.map((country) => country.iso)

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="address-content"
        contentLabel="Address Form"
      >
        <div className="">
          <h4>Basic Details Verification</h4>
          <Formik
            enableReinitialize 
            initialValues={{
              address: kycDetail?.address,
              city: kycDetail?.city,
              state: kycDetail?.state,
              country: kycDetail?.country,
              zipCode: kycDetail?.zip_code,
              nationalIDNumber: kycDetail?.national_id_number,
              phone: `+${kycDetail?.phone_code}${kycDetail?.phone}`
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values, setSubmitting);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form className="form">
                <div className="form-group">
                  <label htmlFor="street">Address</label>
                  <Field type="text" name="address" className="form-control" />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <Field type="text" name="city" className="form-control" />
                  <ErrorMessage name="city" component="div" className="error" />
                </div>
                <div className="form-group">
                  <label htmlFor="state">State</label>
                  <Field type="text" name="state" className="form-control" />
                  <ErrorMessage
                    name="state"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="state">Country</label>
                  <Field
                    name="country"
                    className="form-control"
                    component={SelectField}
                    validate={required}
                    options={countryOptions}
                    onChange={(option: Option) => {
                      setFieldValue("country", option.value);
                      handleIDType(option.label)
                    }}
                  />
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <Field name="phone" className="form-control">
                    {({ field }) => (
                      <PhoneInput
                        {...field}
                        international
                        defaultCountry="US"
                        countries={allowedCountries}
                        countryCallingCodeEditable={false}
                        onChange={(value) => setFieldValue('phone', value)}
                      />
                    )}

                  </Field>
                  <ErrorMessage name="phone" component="div" className="error" />
                </div>
                <div className="form-group">
                  <label htmlFor="nationalIDNumber">{selectedCountry.idName}</label>
                  <Field type="text" name="nationalIDNumber" className="form-control" />
                  <ErrorMessage name="nationalIDNumber" component="div" className="error" />
                </div>
                <div className="form-group">
                  <label htmlFor="zipCode">ZIP Code</label>
                  <Field type="text" name="zipCode" className="form-control" />
                  <ErrorMessage
                    name="zipCode"
                    component="div"
                    className="error"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                  style={{ marginLeft: "1em" }}
                >
                  {!kycDetail ? "Submit Details" : "Update Details"}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default AddressFormModal;
